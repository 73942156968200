import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    valForm: FormGroup;

    constructor(public settings: SettingsService, public af: AngularFireAuth, public toastr: ToastrService, fb: FormBuilder) {
        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])]
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (const c in this.valForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.valForm.controls[c].markAsTouched();
            }

        }
        if (this.valForm.valid) {
            console.log('Valid!');
            console.log(value);
            this.af.sendPasswordResetEmail(value.email)
            .then(() => this.toastr.success('Please check your email for instructions to reset the password!', 'Success!'))
            .catch((error) => this.toastr.error('Error resetting password. Error' + error, 'Error!'))
        }
    }

    ngOnInit() {
    }

}
