<div class="content-heading">View Users
<small>
        <a [routerLink]="['/users/manageUsers']"> Manage Users </a>->
            View Users 
 </small>
 </div>
<div class="col-masonry">
        <div class="panel b m0">
            <div class="panel-heading">
            </div>
            <div class="panel-body">
                <div class="row">
                     <div class="col-lg-8">
                     <div class="row">
                    <div class="col-lg-5">
                        <h4 class="media-heading">Name</h4>
                    </div>
                    <div class="col-lg-6">
                        <p>{{userDetails.name}}</p>
                    </div>
                    </div>
                    </div>
                     <div class="col-lg-8">
                     <div class="row">

                    <div class="col-lg-5">
                        <h4 class="media-heading">Email</h4>
                    </div>
                    <div class="col-lg-6">
                        <p>{{userDetails.email}}</p>
                    </div>
                    </div>
                    </div>
                     <div class="col-lg-8">
                        <div class="row">
                            <div class="col-lg-5">
                                <h4 class="media-heading">Mobile Number</h4>
                            </div>
                            <div class="col-lg-6">
                                <p>{{userDetails.mobileNo}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-5">
                                    <h4 class="media-heading">Role</h4>
                                </div>
                                <div class="col-lg-6">
                                    <p>{{userDetails.role}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="panel-body">
            </div>
        </div>
    </div>
