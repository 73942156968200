import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
@Component({
  selector: 'app-view-news',
  templateUrl: './view-news.component.html',
  styleUrls: ['./view-news.component.scss']
})
export class ViewNewsComponent {

newsDetails: any= {};
newsdata: AngularFireObject<any>;
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase) {
  	 	 	this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
  	 	 	 	console.log('id' + JSON.stringify(Id));
  	 	if (Id != null) {
        this.newsdata = this.af.object('/news/' + Id);
        this.newsdata.valueChanges().subscribe(
        (response) => {
        	this.newsDetails = response;
           console.log('response' + JSON.stringify(response));
         }
      );
          }
       });
  }

}
