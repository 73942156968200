<div class="content-heading"> Setting
   <small><a [routerLink]=" ['/home'] ">Dashboard</a> -> Setting</small>
</div>
<div class="row">
   <div class="col-sm-12">
   <!-- START panel-->
      <div class="panel panel-default">
         <div class="panel-heading"><strong>Global</strong> Setting</div>
         <div class="panel-body">
            <form role="form" (ngSubmit)="onSubmitSetting(f)" #f="ngForm">
               <div class="form-group">
                  <label>GST %*</label>
                  <input class="form-control" type="number" name="totalGST" id="totalGST" [(ngModel)]="settings.totalGST" placeholder="Enter GST %" />
               </div>

               <div class="form-group">
                  <label>Commision % (when seller delivers)*</label>
                  <input class="form-control" type="number" name="commission_forsellerdlv" id="commission_forsellerdlv" [(ngModel)]="settings.commission.forsellerdlv" placeholder="Enter Vegecious Commission % when seller delivers." />
               </div>
               
               <div class="form-group">
                  <label>Commision % (when vegecious delivers)*</label>
                  <input class="form-control" type="number" name="commission_forvegeciousdlv" id="commission_forvegeciousdlv" [(ngModel)]="settings.commission.forvegeciousdlv" placeholder="Enter Vegecious Commission % when vegecious delivers." />
               </div>

               <div class="form-group">
                  <label>Working Hours</label>
                  <ngb-timepicker ngDefaultControl name="starttime" id="starttime" [(ngModel)]="settings.workinghours.starttime"></ngb-timepicker>
                  <ngb-timepicker ngDefaultControl name="endtime" id="endtime" [(ngModel)]="settings.workinghours.endtime"></ngb-timepicker>
               </div>

               <div class="form-group">
                  <label for="useRazorPay">Enable RazorPay Provider</label>
                  <input class="form-control" style="width: 20%; display: inline-block;" type="checkbox" name="useRazorPay" id="useRazorPay" [(ngModel)]="settings.paymentproviders.useRazorPay">
               </div>

               <div class="form-group">
                  <label for="useCOD">Enable Cash on Delivery (COD)</label>
                  <input class="form-control"  style="width: 20%;  display: inline-block;" type="checkbox" name="useCOD" id="useCOD" [(ngModel)]="settings.paymentproviders.useCOD">
               </div>

               <div class="form-group">
                  <label>Theme</label>
                  <input class="form-control" type="text" name="theme" id="theme" [(ngModel)]="settings.theme" placeholder="Theme" />
               </div>

               <div class="form-group">
                  <label>Delivery Charge</label>
                  <input class="form-control" type="number" name="deliverycharge" id="deliverycharge" [(ngModel)]="settings.deliverycharge" placeholder="Delivery Charge" />
               </div>

               <div class="form-group">
                  <label for="showFeaturedChefs">Show Featured Chefs</label>
                  <input class="form-control" style="width: 20%; display: inline-block;" type="checkbox" name="showFeaturedChefs" id="showFeaturedChefs" [(ngModel)]="settings.showFeaturedChefs">
               </div>

               <button class="btn btn-sm btn-success" type="submit">Save</button>
            </form>
         </div>
      </div>
      <!-- END panel-->
   </div>
</div>