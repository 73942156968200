import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-tages',
  templateUrl: './edit-tages.component.html',
  styleUrls: ['./edit-tages.component.scss']
})
export class EditTagesComponent {

tagDetails: any= {};
tagsdata: AngularFireObject<any>;
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase, public toastr: ToastrService) {
  	 	 	this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
  	 	if (Id != null) {
		        this.tagsdata = this.af.object('/tags/' + Id);
		        this.tagsdata.valueChanges().subscribe(
		        (response) => {
		        	this.tagDetails = response;
		         }
		      );
          }
       });
  }
    onSubmitTag(form: NgForm) {
    	  this.tagsdata.update({
    	  	tags: this.tagDetails.tags,
    	  }).then((res) => {
           this.toastr.success('Tags Updated Successfully!', 'Success!');
        this.router.navigate(['/tags/all']);
      });
    }

      cancel() {
       this.router.navigate(['/tags/all']);
    }
}
