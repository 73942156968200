import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';


@Injectable()
export class UserService {
    currentuser: any;
    constructor(public af: AngularFireDatabase, private functions: AngularFireFunctions) {
        this.currentuser = null;
    }

    getUserById(id: string): Promise<any> {
        const p = new Promise((resolve, reject) => {
            this.af.object('/users/' + id).valueChanges().subscribe((user: any) => {
                resolve(user);
            }, (err) => {
                console.log('Error retrieving user.' + err);
                reject(err);
            });
        });        
        return p;
    }

    getCurrentUser() {
        return this.currentuser;
    }

    isAdmin() {
        if (this.currentuser == null) {
            return false;
        } else {
            return (this.currentuser.role === 'admin');
        }
    }
    
    getCurrentUserOnAuth(): Promise<any> {
        return this.currentuser;
    }
    setCurrentUser(_currentuser: any): boolean {
        console.log('Current user set as : ' + JSON.stringify(_currentuser));
            this.currentuser = _currentuser;
            /*this.af.list('/users', ref => ref.orderByChild('sellerId').equalTo(seller.uid).limitToFirst(1)).valueChanges().subscribe((res) => {
                this.sellerinfo.business = res[0] || {
                    storeName: '',
                    email: '',
                    description: '',
                    address: '',
                    officeLocation: '',
                    phoneNo: ''
                };
                resolve(this.sellerinfo);
            });*/

        return true;
    }

    clearCurrentUser() {
        this.currentuser = null;
    }

    getUserList(): Promise<any> {
        const getUserListPromise = new Promise<any>((resolve, reject) => {
            const getUserListCallable = this.functions.httpsCallable('getAllUsers');
            getUserListCallable(null).subscribe(async res => {
                console.log(res);
                resolve(res);
            });
        });

        return getUserListPromise;
    }

    documentToFeatureChefsObject = _ => {
        const tmpobject = _.payload.val();
        const object = {
            businesskey: tmpobject.businesskey,
            id: _.payload.key,
            thumb: tmpobject.thumb,
            title: tmpobject.title
        };
        console.log(JSON.stringify(object));
        return object;
    }

    featuredchefs: any = null;
    getFeatureChefs(): Promise<any> {
        const p = new Promise((resolve, reject) => {
            this.af.list('/featuredchefs').snapshotChanges().subscribe((allfeaturedchefsresponse: any[]) => {
                this.featuredchefs = {};
                allfeaturedchefsresponse.forEach((_o: any) => {
                    const _featuredchef = _o.payload.toJSON();
                    _featuredchef.$key = _o.key;
                     this.featuredchefs[_featuredchef.businesskey] = _featuredchef;
                });
                resolve(this.featuredchefs);
            }, (err) => {
                console.log('Error retrieving all featured sellers.' + err);
                reject(err);
            });
        });        
        return p;
    }

    IsFeaturedChef(businesskey: string): Promise<string> {
        const p = new Promise<string>((resolve, reject) => {
            this.getFeatureChefs().then((chefs: any[]) => {
                let found = chefs[businesskey] != null;
                console.log('Featured Chef: ' + found);
                let retval = found ? chefs[businesskey].$key : null;
                resolve(retval);
            }, (error) => {
                reject(null);
            });
        });        
        return p;
    }

    setFeaturedChef(userdetails: any, newstate: boolean): Promise<boolean> {
        let businesskey = userdetails.businessinfo.key;
        let featuredchefkey = userdetails.featuredchefkey;
        const p = new Promise<boolean>((resolve, reject) => {
            this.getFeatureChefs().then((chefs: any[]) => {
                let found = chefs[businesskey] != null;
                if (newstate) {
                    if (found) {
                        console.log('Chef allready featured... Doing nothing.')
                        resolve(false);
                    } else {
                        let featuredchefsref = this.af.list('/featuredchefs');
                        let newfeaturechef = {
                            businesskey: userdetails.businessinfo.key || '',
                            thumb: userdetails.businessinfo.thumb || '',
                            title: userdetails.businessinfo.title || ''
                        }
                        console.log('Inserting Featured Chef..' + JSON.stringify(newfeaturechef));
                        featuredchefsref.push(newfeaturechef);
                        resolve(true);
                    }
                } else {
                    // Remove featured chef.
                    if (!found) {
                        console.log('Chef does not exist. Cant remove.... Doing nothing.')
                        resolve(false);
                    } else {
                        let featuredchefsref = this.af.list('/featuredchefs');
                        featuredchefsref.remove(featuredchefkey);
                        console.log('Removed Featured Chef..' + featuredchefkey);
                        resolve(true);
                    }
                }
            })
        });        
        return p;
        console.log(JSON.stringify(userdetails));
    }
}
