import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import 'rxjs';
import {Configs} from '../../../config';
import {environment} from '../../../../environments/environment';



@Injectable()
export class PushNotificationService {

  url: any = 'https://onesignal.com/api/v1/notifications';


  constructor(private http: HttpClient) {
  }

  sendNotification(message) {
    const body = JSON.stringify(message);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // copy your Rest api key below ;
    headers.append('Authorization', 'Basic ' + Configs[environment.envname].onesignal.apiKey);
    return this.http.post(this.url, body, {
      headers: headers
    }).pipe(map((data: Response) => data.json()));

  }
}
