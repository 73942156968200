import {Component, NgZone} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {ToastrService} from 'ngx-toastr';
import {AngularFireAuth} from '@angular/fire/auth';
import {Configs} from 'app/config';
import {environment} from '../../../../../environments/environment';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from 'app/core/user/user.service';
import { Observable, of } from 'rxjs';
import { SellerService } from '../../../../core/seller/seller.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

interface IFSCDETAILS {
    "NEFT": boolean,
    "STATE": string,
    "ADDRESS": string,
    "BRANCH": string,
    "CENTRE": string,
    "DISTRICT": string,
    "CITY": string,
    "RTGS": boolean,
    "IMPS": boolean,
    "SWIFT": string,
    "UPI": boolean,
    "MICR": string,
    "CONTACT": string,
    "BANK": string,
    "BANKCODE": string,
    "IFSC": string
};

@Component({
    selector: 'app-update-business-bank-details',
    templateUrl: './update-bank-details.component.html',
    styleUrls: ['./update-bank-details.component.scss']
})
export class UpdateBusinessBankDetailsComponent {
    defaultgeo = {
        lat: 51.678418,
        lng: 7.809007,
        isDefaultLatLng: true,
        zoom: 10,
        radius: 5
    };

    ifscdetails: IFSCDETAILS;
    originalprofilepicture = '';
    allLocations$: Observable<string[]>;
    selectedLocation: any[] = [];
    private _disabledV:string = '0';
    public disabled:boolean = false;
    maxtags = Configs[environment.envname].menuitems.maxtags || 1;
    maxlocations = Configs[environment.envname].menuitems.maxlocations || 1;
    
    business = {
        $key: null,
        email: '',
        description: '',
        address: '',
        facebookLink: '',
        twitterLink: '',
        officeLocation: '',
        bankaccountinfo : {
            beneficiary_name: '',
            ifsc_code: '',
            account_number: ''
        },
        phoneNo: '',
        storeName: '',
        sellerId: '',
        profileimage: Configs[environment.envname].defaultprofileimage,
        locationsserved: '',
        razorpay: {
            account_id: '',
            status: '',
            jsonresult: ''
        },
        geo: this.defaultgeo
    };
    businessData: AngularFireList<any>;
    razorpayaccount = {
        name: '',
        email: '',
        sellerid: '',
        tnc_accepted: true,
        account_details: {
            business_name: '',
            business_type: 'individual'
        },
        bank_account: {
            ifsc_code: '',
            ifsc_code2: '',
            beneficiary_name: '',
            account_type: 'current',
            account_number: '',
            account_number2: ''
        }
    };

    constructor(public af: AngularFireDatabase,
                public afa: AngularFireAuth,
                public storage: AngularFireStorage,
                public toastr: ToastrService,
                private http: HttpClient,
                private route: ActivatedRoute,
                public router: Router, 
                private __zone: NgZone,
                private ng2ImgMax: Ng2ImgMaxService,
                private sellerservice: SellerService,
                private userservice: UserService) {
        this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
            let business_user: any = null;
            if (Id == null) {
                let _user = this.userservice.getCurrentUser(); 
                Id = _user.uid;
            }
            this.ifscdetails = {
                NEFT: false,
                STATE: '',
                ADDRESS: '',
                BRANCH: '',
                CENTRE: '',
                DISTRICT: '',
                CITY: '',
                RTGS: false,
                IMPS: false,
                SWIFT: '',
                UPI: false,
                MICR: '',
                CONTACT: '',
                BANK: '',
                BANKCODE: '',
                IFSC: ''
            }
            console.log('Update Bank Info for ' + Id);
            this.allLocations$ = this.sellerservice.getLocations();
            this.businessData = af.list('/business', ref => ref.orderByChild('sellerId').equalTo(Id).limitToFirst(1));
            this.business.sellerId = Id;
            this.businessData.snapshotChanges().subscribe((businessdataresponse: any[]) => {
                const _businessdatas = [];
                businessdataresponse.forEach((_o: any) => {
                    const _businessdata = _o.payload.toJSON();
                    _businessdata.$key = _o.key;
                    _businessdatas.push(_businessdata);
                })
    
                this.business = _businessdatas[0] || this.business;
                if (this.business.razorpay == null) {
                    this.business.razorpay = {
                        account_id: '',
                        status: '',
                        jsonresult: ''
                    }
                }
                this.business.geo = this.defaultgeo;
                this.razorpayaccount.name = this.business.storeName;
                this.razorpayaccount.sellerid = Id;
                this.razorpayaccount.email = this.business.email;
                this.razorpayaccount.account_details.business_name = this.business.storeName || '';
                this.business.bankaccountinfo = this.business.bankaccountinfo || { beneficiary_name: '', ifsc_code: '', account_number: ''};
                this.razorpayaccount.bank_account.beneficiary_name = this.business.bankaccountinfo.beneficiary_name || '';
                this.razorpayaccount.bank_account.ifsc_code = this.business.bankaccountinfo.ifsc_code || '';
                this.razorpayaccount.bank_account.ifsc_code2 = this.business.bankaccountinfo.ifsc_code || '';
                this.razorpayaccount.bank_account.account_number = this.business.bankaccountinfo.account_number || '';
                this.razorpayaccount.bank_account.account_number2 = this.business.bankaccountinfo.account_number || '';
                console.log(this.business);
                this.lookupIFSC();
                this.originalprofilepicture = this.business.profileimage;
                this.selectedLocation = (this.business.locationsserved == null ? [] : this.business.locationsserved.split(',')).map((str, index) => ({text: str, id: index+1}));
            });
        });
    }

    private getUserLocation() {
        /// locate the user
        if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(position => {
            this.defaultgeo.lat = position.coords.latitude;
            this.defaultgeo.lng = position.coords.longitude;
          });
        }
    }

    uploadedimage: any = null;
    url: any = '';

    readUrl(event) {
        if (event.target.files && event.target.files[0]) {
            const profileimagefile = event.target.files[0];
            this.ng2ImgMax.resizeImage(profileimagefile, Configs[environment.envname].userprofile.imagesize.width, Configs[environment.envname].userprofile.imagesize.height).subscribe((imageResized: File) => {
                this.uploadedimage = imageResized;
                const reader = new FileReader();
                reader.onload = (event2: any) => {
                    this.url = event2.target.result;
                    this.business.profileimage = event2.target.result;
                     console.log('Image resized.')
                }
                reader.readAsDataURL(event.target.files[0]);
            }, (error) => {
                console.log('😢 Oh no. Image Resize Error!', error);
            });
        }
    }

    radiusChanged(newradius: any) {
        this.business.geo.radius = newradius / 1000;
    }

    markerDragEnd($event: any) {
        console.log('dragEnd', $event);
        this.business.geo.lat = $event.coords.lat;
        this.business.geo.lng = $event.coords.lng;
        this.business.geo.isDefaultLatLng = false;
    }

    onAddressUpdate($event) {
        /*this.gMapsService.getLatLong(this.business.address)
            .subscribe(
                result => {
                    this.__zone.run(() => {
                        this.business.geo.lat = result.lat();
                        this.business.geo.lng = result.lng();
                        this.business.geo.isDefaultLatLng = false;
                    });
                },
                error => console.log(error),
                () => console.log('Geocoding completed!')
            );*/
    }

    saveProfile() {
        let currentUser = this.userservice.getCurrentUser();
        let _selectedlocations = this.selectedLocation.map(eachitem => eachitem.text).join(',') || '';
        if (this.business.$key === null) {
            this.businessData.push({
                email: this.business.email,
                description: this.business.description,
                address: this.business.address,
                facebookLink: this.business.facebookLink,
                twitterLink: this.business.twitterLink,
                officeLocation: this.business.officeLocation,
                bankaccountinfo: {
                    beneficiary_name: this.razorpayaccount.bank_account.beneficiary_name,
                    ifsc_code: this.razorpayaccount.bank_account.ifsc_code,
                    account_number: 'XXXXXXX' + this.razorpayaccount.bank_account.account_number.substring(this.razorpayaccount.bank_account.account_number.length - 4)
                },
                phoneNo: this.business.phoneNo,
                storeName: this.business.storeName,
                sellerId: currentUser.uid,
                profileimage: this.business.profileimage,
                deliveryradius: this.business.geo.radius,
                locationsserved: _selectedlocations,
                razorpay: this.business.razorpay
            }).then((res) => {
                this.toastr.success('Your Business details added!', 'Success!');
            });
        } else {
            this.af.object('/business/' + this.business.$key)
                .update({
                    email: this.business.email,
                    description: this.business.description,
                    address: this.business.address,
                    facebookLink: this.business.facebookLink,
                    twitterLink: this.business.twitterLink,
                    officeLocation: this.business.officeLocation,
                    bankaccountinfo: {
                        beneficiary_name: this.razorpayaccount.bank_account.beneficiary_name,
                        ifsc_code: this.razorpayaccount.bank_account.ifsc_code,
                        account_number: 'XXXXXXX' + this.razorpayaccount.bank_account.account_number.substring(this.razorpayaccount.bank_account.account_number.length - 4)
                    },
                    phoneNo: this.business.phoneNo,
                    storeName: this.business.storeName,
                    sellerId: currentUser.uid,
                    profileimage: this.business.profileimage,
                    deliveryradius: this.business.geo.radius,
                    locationsserved: _selectedlocations,
                    razorpay: this.business.razorpay
                })
                .then((res) => {
                    this.toastr.success('Your Business details updated!', 'Success!');
                });
        }
    }

    onAddBusinessBankInfo(form: NgForm) {        
        Swal.fire({
            title: 'Confirm Bank Account Information?',
            html: '<p>Please validate bank information is correct.<p><table class="table"><tbody><tr><td>Bank IFSC Code:</td><td style="font-weight: bold;">' + this.razorpayaccount.bank_account.ifsc_code + '</td></tr><tr><td>Bank Acct Number:</td><td  style="font-weight: bold;">' + this.razorpayaccount.bank_account.account_number + '</td></tr><tr><td>Bank Acct Name:</td><td style="font-weight: bold;">' + this.razorpayaccount.bank_account.beneficiary_name + '</td></tr><tbody></table>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Its Correct!',
            cancelButtonText: 'No, I need to correct!',
            preConfirm: function() {
              return new Promise<void>(function(resolve) {
                setTimeout(function() {
                  resolve()
                }, 2000)
              })
            }
          }).then((result: any) => {
            if (result.value) {            
                let _razorpayaccount = {
                    name: this.razorpayaccount.name,
                    sellerid: this.razorpayaccount.sellerid,
                    email: this.razorpayaccount.email,
                    tnc_accepted: this.razorpayaccount.tnc_accepted,
                    account_details: {
                        business_name: this.razorpayaccount.account_details.business_name,
                        business_type: this.razorpayaccount.account_details.business_type,
                    },
                    bank_account: {
                        ifsc_code: this.razorpayaccount.bank_account.ifsc_code,
                        account_number: this.razorpayaccount.bank_account.account_number,
                        account_type: this.razorpayaccount.bank_account.account_type,
                        beneficiary_name: this.razorpayaccount.bank_account.beneficiary_name
                    }
                }    
                this.sellerservice.setBusinessBankInfo(_razorpayaccount).then((res) => {
                    this.business.razorpay = {
                        account_id: res.account_id,
                        status: res.status,
                        jsonresult: res.result
                    }
                    this.saveProfile();
                }).catch((exception) => {
                    console.log(exception);
                });
                console.log('Saving');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
          });
    }


    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
      
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

    lookupIFSC() {
        console.log('IFSC lookup for: ' + this.razorpayaccount.bank_account.ifsc_code);
        let apiUrl = ' https://ifsc.razorpay.com/' + this.razorpayaccount.bank_account.ifsc_code;
        // Http Options
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }

        this.http.get<IFSCDETAILS>(apiUrl).pipe(retry(1),  catchError(this.handleError<IFSCDETAILS>('getIFSCDetails'))).subscribe((res) => {
            if (res != null) {
                console.log(res);
                this.ifscdetails = res;
            } else {
                this.ifscdetails = {
                    NEFT: false,
                    STATE: '',
                    ADDRESS: 'Please correct IFSC Code.',
                    BRANCH: '',
                    CENTRE: '',
                    DISTRICT: '',
                    CITY: '',
                    RTGS: false,
                    IMPS: false,
                    SWIFT: '',
                    UPI: false,
                    MICR: '',
                    CONTACT: '',
                    BANK: 'Invalid IFSC Code',
                    BANKCODE: '',
                    IFSC: ''
                }
            }
        })
    }

    private get disabledV():string {
        return this._disabledV;
    }
    
    private set disabledV(value:string) {
        this._disabledV = value;
        this.disabled = this._disabledV === '1';
    }
    
    public selected(value:any):void {
        console.log('Selected value is: ', value);
    }
    
    public removed(value:any):void {
        console.log('Removed value is: ', value);
    }
    
    public typed(value:any):void {
        console.log('New search input: ', value);
    }
    
    public refreshValue(value:any):void {
        this.selectedLocation = value.map(eachitem => {
            return eachitem.text;
        });
    }

    public showLinkedBankAccount() {
        this.router.navigate(['/businessInfo/business-bank-details']);
    }

    public IsValidForm() {
        let validIFSCReg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
        let validbankacctnumber = '[0-9]{9,18}';

        let IsValidIFSCCode1 = ((this.razorpayaccount.bank_account.ifsc_code.length > 0) && (this.razorpayaccount.bank_account.ifsc_code.match(validIFSCReg) != null ? true : false));
        let IsValidIFSCCode2 = ((this.razorpayaccount.bank_account.ifsc_code2.length > 0) && (this.razorpayaccount.bank_account.ifsc_code2.match(validIFSCReg) != null ? true : false));
        let IsValidIFSCCode = IsValidIFSCCode1 && IsValidIFSCCode2  && (this.razorpayaccount.bank_account.ifsc_code == this.razorpayaccount.bank_account.ifsc_code2);

        let isValidBankAcct1 = ((this.razorpayaccount.bank_account.account_number.length > 0) && (this.razorpayaccount.bank_account.account_number.match(validbankacctnumber) != null ? true : false));
        let isValidBankAcct2 = ((this.razorpayaccount.bank_account.account_number2.length > 0) && (this.razorpayaccount.bank_account.account_number2.match(validbankacctnumber) != null ? true : false));
        let isValidBankAcct = isValidBankAcct1 && isValidBankAcct2  && (this.razorpayaccount.bank_account.account_number == this.razorpayaccount.bank_account.account_number2);

        let isValidAccountName = this.razorpayaccount.bank_account.beneficiary_name.length > 3;
        console.log('Form Valid IFSC: ' + IsValidIFSCCode);
        console.log('Form Valid ACC #: ' + isValidBankAcct);
        console.log('Form Valid ACC Name: ' + isValidAccountName);
        return !(IsValidIFSCCode && isValidBankAcct && isValidAccountName);    
    }
}