<div class="content-heading">Categories
<div class="pull-right">
            <label class="file-upload">
              <a [routerLink]="'/categories/addCategory'">  <button class="btn btn-warning"><em class="fa fa-plus"></em>&nbsp;&nbsp; Add New Category</button> </a>  
            </label>
 </div><br/><br/>
<small><a [routerLink]=" ['/home'] ">Dashboard</a>  ->  Categories</small>
</div>

<div class="panel panel-default">
<div class="panel-heading">Categories Details</div>
  <div class="table-responsive b0 customTable">
    <table class="table table-striped" [mfData]="categories" #mf="mfDataTable" [mfRowsOnPage]="5">
        <thead>
        <tr>
            <th class="thsearch text-center">
                <mfDefaultSorter by="siteName">Name</mfDefaultSorter>
                <form (keyup)="getCategory(siteName.value)">
                    <input class="form-control searchinput" type="text" name="siteVal"
                           id="siteVal" [(ngModel)]="siteVal"
                           placeholder="Search" #siteName>
                </form>
            </th>
            <th class="thnosearch text-center">
                <mfDefaultSorter>Edit</mfDefaultSorter>
            </th>
            <th class="thnosearch text-center">
                <mfDefaultSorter>Delete</mfDefaultSorter>
            </th>
             <th class="thnosearch text-center">
                <mfDefaultSorter>View</mfDefaultSorter>
            </th>
            
            <th class="thnoitem"></th>
        </tr>
        </thead>
        <tbody>
        <tr class=" text-center" *ngFor="let item of mf.data">
            <td>{{item.title}}</td>
            <td>
             <button class="btn btn-sm btn-primary" type="button" (click)="categoryEdit(item.$key)"><em class="fa fa-pencil"></em></button>
             </td>
             <td>
               <button class="btn btn-sm btn-danger" type="button" (click)="categoryDelete(item.$key)"><em class="fa fa-trash-o"></em></button>
            </td>
            <td>
             <button class="btn btn-sm btn-default" type="button" (click)="categoryShow(item.$key)"><em class="fa fa-eye"></em></button>
            </td>
            
        </tr>
        </tbody>
        <tfoot>
        <tr>
            <td colspan="5" class="customPagination">
                <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
            </td>
        </tr>
        </tfoot>
    </table>
</div>

<div class="panel-footer">
        <div class="row">
        
        </div>
    </div>
</div>

