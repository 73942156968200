import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {ToastrService} from 'ngx-toastr';
import {Configs} from '../../../../config';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.scss']
})
export class AddCategoriesComponent  {
  url: any= '';
  category: any= {};
  categoryData: AngularFireList<any>;
  imageId: string;


  constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService) {
    this.categoryData = af.list('/categories');
    // Override onSuccessItem to retrieve the imageId
    /*this.uploader.onAfterAddingFile = (item: any) => {
      if (this.uploader.options.url.startsWith('http://')) {
        this.uploader.options.url = this.uploader.options.url.replace('http://', 'https://');
      }
      item.url = this.uploader.options.url;
      return item;
    };*/
  }
  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event2: any) => {
        this.url = event2.target.result;
        // this.imageRef = 1;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmitCategory(form: NgForm) {
   //this.uploader.uploadAll();
    /*this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
    const res: any = JSON.parse(response);
    this.category.thumb = res.url;
    this.categoryData.push(this.category).then((res2) => {
      this.toastr.success('Categories Data Added Successfully!', 'Success!');
      this.router.navigate(['/categories/manageCategories']);
    });
    };*/
  }

  cancel() {
    this.router.navigate(['/categories/manageCategories']);
  }
}

