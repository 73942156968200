import {NgModule} from '@angular/core';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';

import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { Error404Component } from './pages/error404/error404.component';

import { HomeComponent } from './home/home/home.component';


import {CategoriesComponent} from './restaurant/categories/categories.component';
import {AddCategoriesComponent} from './restaurant/categories/add-categories/add-categories.component';
import {MenuItemsComponent} from './restaurant/menu-items/menu-items.component';
import {AddItemComponent} from './restaurant/menu-items/add-item/add-item.component';
import {OrdersComponent} from './restaurant/orders/orders.component';
import {ViewOrderComponent} from './restaurant/orders/view-order/view-order.component';

import {ChefsComponent} from './restaurant/chefs/chefs.component';
import {AddChefComponent} from './restaurant/chefs/add-chef/add-chef.component';
import {ViewChefComponent} from './restaurant/chefs/view-chef/view-chef.component';

import {UsersComponent} from './restaurant/users/users.component';
import {AddUserComponent} from './restaurant/users/add-user/add-user.component';
import {ViewUserComponent} from './restaurant/users/view-user/view-users.component';

import {SettingsComponent} from './restaurant/settings/settings.component';
import {TagsComponent} from './restaurant/tags/tags.component';
import {EditTagesComponent} from './restaurant/tags/edit-tages/edit-tages.component';
import {AddTagsComponent} from './restaurant/tags/add-tags/add-tags.component';
import {BusinessInfoComponent} from './restaurant/business-info/business-info.component';
import {ProfileComponent} from './restaurant/profile/profile.component';
import {EditCategoryComponent} from './restaurant/categories/edit-category/edit-category.component';
import {ViewCategoryComponent} from './restaurant/categories/view-category/view-category.component';
import {ViewItemComponent} from './restaurant/menu-items/view-item/view-item.component';
import {EditItemComponent} from './restaurant/menu-items/edit-item/edit-item.component';
import {NewsComponent} from './restaurant/news/news.component';
import {AddNewsComponent} from './restaurant/news/add-news/add-news.component';
import {EditNewsComponent} from './restaurant/news/edit-news/edit-news.component';
import {ViewNewsComponent} from './restaurant/news/view-news/view-news.component';
import {AuthService} from './pages/login/auth.service';
import {LoginService} from './pages/login/login.service';

import {CouponsComponent} from './restaurant/coupons/coupons.component';
import {AddCouponsComponent} from './restaurant/coupons/add-coupons/add-coupons.component';

import { PushNotificationComponent } from './restaurant/push-notification/push-notification.component';
import { LocationsComponent } from './restaurant/locations/locations.component';
import { AddLocationsComponent } from './restaurant/locations/add-locations/add-locations.component';
import { EditLocationsComponent } from './restaurant/locations/edit-locations/edit-locations.component';
import { UpdateBusinessBankDetailsComponent } from './restaurant/business-info/update-bank-details/update-bank-details.component';


export const routes = [
    {path: '', component: LoginComponent, useAsDefault: true},
    {path: 'login', component: LoginComponent},
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'home', component: HomeComponent, canActivate: [AuthService]},
            // { path: 'pushNotification', component: PushNotificationComponent, canActivate: [AuthService]},
            {
                path: 'coupons',
                children: [
                    {path: 'all', component: CouponsComponent, canActivate: [AuthService]},
                    {path: 'addCoupons', component: AddCouponsComponent, canActivate: [AuthService]}

               ]
            },
            /*{
                path: 'categories',
                children: [
                    {path: 'manageCategories', component: CategoriesComponent, canActivate: [AuthService]},
                    {path: 'addCategory', component: AddCategoriesComponent, canActivate: [AuthService]},
                    {path: 'editCategory/:id', component: EditCategoryComponent, canActivate: [AuthService]},
                    {path: 'viewCategory/:id', component: ViewCategoryComponent, canActivate: [AuthService]}
                ]
            },*/
            {
                path: 'news',
                children: [
                    {path: 'manageNews', component: NewsComponent, canActivate: [AuthService]},
                    {path: 'addNews', component: AddNewsComponent, canActivate: [AuthService]},
                    {path: 'editNews/:id', component: EditNewsComponent, canActivate: [AuthService]},
                    {path: 'viewNews/:id', component: ViewNewsComponent, canActivate: [AuthService]}
                ]
            },
            {
                path: 'order',
                children: [
                    {path: 'allOrder', component: OrdersComponent, canActivate: [AuthService]},
                    {path: 'viewOrder/:id', component: ViewOrderComponent, canActivate: [AuthService]},

                ]
            },
            {
                path: 'menu',
                children: [
                    {path: 'manageItems', component: MenuItemsComponent, canActivate: [AuthService]},
                    {path: 'addItems', component: AddItemComponent, canActivate: [AuthService]},
                    {path: 'viewItems/:id', component: ViewItemComponent, canActivate: [AuthService]},
                    {path: 'editItems/:id', component: EditItemComponent, canActivate: [AuthService]},

                ]
            },
            {
                path: 'chefs',
                children: [
                    {path: 'manageChefs', component: ChefsComponent, canActivate: [AuthService]},
                    {path: 'addChef', component: AddChefComponent, canActivate: [AuthService]},
                    {path: 'viewChef/:id', component: ViewChefComponent, canActivate: [AuthService]}

                ]
            },
            {
                path: 'users',
                children: [
                    {path: 'manageUsers', component: UsersComponent, canActivate: [AuthService]},
                    {path: 'addUser', component: AddUserComponent, canActivate: [AuthService]},
                    {path: 'viewUser/:id', component: ViewUserComponent, canActivate: [AuthService]}

                ]
            },
            {
                path: 'tags',
                children: [
                    {path: 'all', component: TagsComponent, canActivate: [AuthService]},
                    {path: 'addTags', component: AddTagsComponent, canActivate: [AuthService]},
                    {path: 'editTags/:id', component: EditTagesComponent, canActivate: [AuthService]}
                ]
            },
            {
                path: 'locations',
                children: [
                    {path: 'all', component: LocationsComponent, canActivate: [AuthService]},
                    {path: 'addLocations', component: AddLocationsComponent, canActivate: [AuthService]},
                    {path: 'editLocations/:id', component: EditLocationsComponent, canActivate: [AuthService]}
                ]
            },
            {path: 'setting', component: SettingsComponent, canActivate: [AuthService]},
            {
                path: 'businessinfo', 
                children: [
                    {path: 'my', component: BusinessInfoComponent, canActivate: [AuthService]},
                    {path: 'chef/:id', component: BusinessInfoComponent, canActivate: [AuthService]},
                    {path: 'update-business-bank-details/:id', component: UpdateBusinessBankDetailsComponent, canActivate: [AuthService]}
                ]
            },                
            {path: 'UserProfile', component: ProfileComponent, canActivate: [AuthService]},

        ] // children End
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: '404', component: Error404Component },

    // Not found
    { path: '**', redirectTo: '404' }

];


