import { Component, HostBinding, OnInit } from '@angular/core';
declare var $: any;

import { SettingsService } from './core/settings/settings.service';
import { ThemesService } from './core/themes/themes.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { SellerService } from 'app/core/seller/seller.service';
import { MenuService } from 'app/core/menu/menu.service';
import { Configs } from './config';
import { environment } from 'environments/environment';
import { UserService } from './core/user/user.service';
import { menu, adminmenu } from './routes/menu';
import { PwaUpdateService } from './core/pwaupdate/pwaupdate.service';
import { FcmWebService } from './core/fcm/fcmweb.service';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.layout.isFixed; }
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.layout.isCollapsed; }
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.layout.isBoxed; }
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.layout.useFullLayout; }
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.layout.hiddenFooter; }
    @HostBinding('class.layout-h') get horizontal() { return this.settings.layout.horizontal; }
    @HostBinding('class.aside-float') get isFloat() { return this.settings.layout.isFloat; }
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.layout.offsidebarOpen; }
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.layout.asideToggled; }
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.layout.isCollapsedText; }

    constructor(public settings: SettingsService,
                public themes: ThemesService,
                public afauth: AngularFireAuth,
                private router: Router,
                private af: AngularFireDatabase,
                private seller: SellerService,
                private fcmweb: FcmWebService,
                private menuservice: MenuService,
                public toastr: ToastrService,
                private userService: UserService,
                private pwaUpdateServer: PwaUpdateService
            ) { }

    ngOnInit() {
        const defaulttheme = this.themes.getDefaultTheme();
        console.log('Running in ' + environment.envname);
        this.themes.setTheme(Configs[environment.envname].defaulttheme);
        this.menuservice.clearMenu();
        $(document).on('click', '[href="#"]', e => e.preventDefault());

        this.afauth.authState.subscribe((user) => {
            console.log('Authstate changed....');
            if (!user) {
                this.router.navigate(['login']);
            } else {
                this.af.object('/users/' + user.uid).valueChanges().subscribe((res: any) => {
                    res.uid = user.uid;
                    this.userService.setCurrentUser(res);
                    this.menuservice.clearMenu();
                    this.menuservice.addMenu(menu);
                    const thisname = res.name;
                    if ((res.profilepictureurl != null) && (res.profilepictureurl.length > 0)) {
                      console.log('Setting profile picture: ' + res.profilepictureurl);
                      const thisprofilepicture = res.profilepictureurl;
                    } else {
                      console.log('Not Setting profile picture');
                    }
                    this.notificationWebSetup(res.uid);
                    if (res.role === 'admin') {                       
                        this.menuservice.addMenu(adminmenu);
                    }
                    this.menuservice.reloadMenu();
                    this.seller.setSeller({
                        uid: user.uid,
                        email: user.email,
                        mobileNo: res.mobileNo,
                        name: res.name,
                        role: res.role
                    }).then((res2) => {
                        let business = {
                            email: user.email,
                            description: '',
                            address: '',
                            facebookLink: '',
                            twitterLink: '',
                            officeLocation: '',
                            phoneNo: '',
                            storeName: '',
                            sellerId: ''
                        };
                        business = res2.business || business;
                        if (
                                (business.email.length === 0) ||
                                (business.description.length === 0) ||
                                (business.address.length === 0) ||
                                (business.phoneNo.length === 0) ||
                                (business.storeName.length === 0)
                         ) {
                            this.router.navigate(['businessinfo/my']);
                        } else {
                            this.router.navigate(['home']);
                        }
                    });
                });
            }
        });
    }

    private async notificationWebSetup(userid: string) {
        console.log('Setting web notifications.');
        await this.fcmweb.requestPermission(userid);
        
        this.fcmweb.onNotification().subscribe((message: any) => {
            // console.log('notification received...' + message);
            this.toastr.warning(message.notification.body, message.notification.title, {
                closeButton: true,
                extendedTimeOut: 0,
                timeOut: 0,
                tapToDismiss: false
            });
        });
      }
}
