import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from 'app/core/seller/seller.service';
import { UserService } from 'app/core/user/user.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    valForm: FormGroup;

    constructor(public af: AngularFireAuth, public settings: SettingsService, fb: FormBuilder, public router: Router, public db: AngularFireDatabase, public toastr: ToastrService, public sellerService: SellerService, public userService: UserService) {

        this.valForm = fb.group({
            'email': ['', Validators.compose([Validators.required, Validators.email])],
            'password': ['', Validators.required],
            'account_remember': [false]
        });

    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (const c in this.valForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.valForm.controls[c].markAsTouched();
            }
        }
        if (this.valForm.valid) {
            // check if user is not a 'User'.
            this.db.list('/users', ref => ref.orderByChild('email').equalTo(value.email).limitToFirst(1)).valueChanges().subscribe((res: any) => {
                console.log(res);
                if ((res != null) && (res.length === 1) && (res[0].role !== 'User')) {
                    this.af.signInWithEmailAndPassword(value.email, value.password).then((success) => {
                        console.log('Firebase success: ' + JSON.stringify(success));
                        const _uid = success.user.uid;
                        this.db.object('/users/' + success.user.uid).valueChanges().subscribe((res: any) => {
                            this.userService.setCurrentUser(res);
                            if (res.role === 'chef') {
                                this.sellerService.setSeller({
                                    uid: _uid,
                                    email: res.email,
                                    mobileNo: res.mobileNo,
                                    name: res.name,
                                    role: res.role
                                }).then(((s) => {
                                    this.router.navigate(['home']) ;
                                    console.log('Firebase res: ' + JSON.stringify(res));
                                    this.toastr.success('Login Successfully!', 'Success!');
                                }));
                            } else if (res.role === 'admin') {
                                this.router.navigate(['home']) ;
                                this.toastr.success('Login Successfully!', 'Success!');
                        } else {                        
                                this.toastr.error('Login Failed!', 'You are not a chef or admin!');
                                localStorage.clear();
                                console.log('Signout....');
                                this.af.signOut().then(() => {
                                    console.log('Signout complete....');
                                    this.userService.clearCurrentUser();
                                    this.router.navigate(['/login']);
                                 });
                            }
                        });
                    }, (err) => {
                        console.log(err);
                        this.toastr.error('Login Failed!', err);
                        this.router.navigate(['login']);
                    });
       
                } else {
                    this.toastr.error('Login Failed!', 'Please check your email and password.');
                }
            });
        }
    }

    ngOnInit() {

    }

}
