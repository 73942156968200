<div class="content-heading">Menu Items
   <small><a [routerLink]=" ['/home'] ">Dashboard</a> -> Menu Items </small>
 </div>
<div class="panel panel-default">
<div class="panel-heading">Items Details</div>
<div class="table-responsive b0">
    <table class="table table-striped" [mfData]="menuItems" #mf="mfDataTable" [mfRowsOnPage]="15">
            <thead>
                <tr>
                    <th class="thnosearch"  *ngIf="isAdmin">Seller Name</th>
                    <th class="thsearch">
                        <mfDefaultSorter by="title">Name</mfDefaultSorter>
                        <form (keyup)="getMenuItems(siteName.value)">
                            <input class="form-control searchinput" type="text" name="siteVal"
                                id="siteVal" [(ngModel)]="siteVal"
                                placeholder="Search" #siteName>
                        </form>
                    </th>
                    <!--<th class="thnosearch">
                        <mfDefaultSorter by="offer.hasOffer">Offers</mfDefaultSorter>
                    </th>-->
                    <th class="thnosearch">
                        <mfDefaultSorter by="price.value">Price</mfDefaultSorter>
                    </th>
                    <!--<th class="thnosearch">
                        Action
                    </th>
                    <th class="thnosearch">
                        Edit
                    </th>
                    <th class="thnosearch">
                        Featured
                    </th>
                    <th class="thnosearch">
                        <mfDefaultSorter by="isActive">Active</mfDefaultSorter>
                    </th>
                    <th class="thnosearch">
                        Delete
                    </th>
                    <th class="thnoitem"></th>-->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of mf.data" [class.isnotactive]="!item.isActive">
                    <td *ngIf="isAdmin">{{item.seller.storename}}</td>
                    <td>
                        <button class="btn btn-sm" style="margin-right: 10px;" type="button" (click)="menuItemEdit(item.$key)"><em class="fa fa-pencil"></em></button> 
                        <span (click)="menuItemShow(item.$key)">{{item.title}}</span> 
                        <span style="font-size: 1em; color: Tomato;"><i *ngIf="item.offer.hasOffer"class="fa fa-border fa-percent fa-sm"></i></span>
                    </td>
                    <td>
                        <span>{{currency}}{{item.price.value}}</span>
                        <button class="btn btn-sm btn-danger actionbtn" type="button" (click)="menuItemDelete(item.$key)"><em class="fa fa-trash-o"></em></button> 
                    </td>
                    <!--<td>
                        <div class="btn-group" role="group" aria-label="action items">
                            
                        </div>                       
                    </td>
                    <td>
                        Featured
                    </td>
                    <td>
                        <span *ngIf="item.isActive">Yes</span>
                        <span *ngIf="!item.isActive">No</span>
                    </td>
                    <td>
                    
                    </td>-->
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td style="border:0;"></td>
                    <td style="border:0;"></td>
                </tr>
                <tr>
                    <td style="border:0;"></td>
                    <td style="border:0;"></td>
                </tr>
                <tr>
                    <td><strong>Current Page</strong></td>
                    <td><strong>Items Per Page</strong></td>
                </tr>
                <tr>                    
                    <td colspan="3" class="customPagination" >
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15,50,100]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
    </table>
    <div class="pull-right">
        <label for="image-input" class="file-upload">
          <a [routerLink]="'/menu/addItems'">  <button class="addmenuitembtn btn btn-warning"><em class="fa fa-plus"></em>&nbsp;&nbsp; Add New Item</button> </a>  
        </label>
    </div>
</div>

<div class="panel-footer">
        <div class="row">        
        </div>
    </div>
</div>
