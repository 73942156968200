import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';
import { UserService } from 'app/core/user/user.service';
import { SellerService } from 'app/core/seller/seller.service';

@Component({
  selector: 'app-view-chef',
  templateUrl: './view-chef.component.html',
  styleUrls: ['./view-chef.component.scss']
})
export class ViewChefComponent {
  userDetails: any= {};
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase, public sellerService: SellerService, public userService: UserService) {
    this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
      if (Id != null) {
        this.af.object('/users/' + Id).valueChanges().subscribe(
          (response) => {
            this.userDetails = response;
            this.userDetails.isFeaturedChef = false;
            this.userDetails.featuredchefkey = 'xyz';
            this.userDetails.sellerid = Id;
            this.userDetails.businessinfo = {
              key: ''
            };
            this.sellerService.getSellerById(Id).then((businessinfo) => {
              console.log('business info: ' + JSON.stringify(businessinfo));
              this.userDetails.businessinfo.key = businessinfo.$key;
              this.userDetails.businessinfo.title = businessinfo.storeName;
              this.userDetails.businessinfo.thumb = businessinfo.profileimage;

              this.userService.IsFeaturedChef(businessinfo.$key).then((featuredchefkey: string)=> {
                this.userDetails.isFeaturedChef = (featuredchefkey != null && featuredchefkey.length > 0);
                this.userDetails.featuredchefkey = featuredchefkey;
              });
            });
          }
        );
      }
    });
  }

  viewBusinessInfo() {
    this.router.navigate(['/businessinfo/chef', this.userDetails.sellerid]);
  }

  setFeaturedChef(userdetails: any, state: boolean) {
    console.log('Set featured chef state as ' + state);
    this.userService.setFeaturedChef(userdetails, state);
  }
}
