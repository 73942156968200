import {Component, OnInit, Inject, LOCALE_ID} from '@angular/core';
import {ColorsService} from '../../../shared/colors/colors.service';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'app/core/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  // Bar chart
  // -----------------------------------
  newData = [];
  barData = {
    labels: [],
    datasets: [{
      data: []
    }]

  };

  barColors = [
    {
      backgroundColor: this.colors.byName('info'),
      borderColor: this.colors.byName('info'),
      pointHoverBackgroundColor: this.colors.byName('info'),
      pointHoverBorderColor: this.colors.byName('info')
    }];

  barOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  // Pie chart
  // -----------------------------------

  pieData = {
    labels: [],
    datasets: [{
      data: []
    }]
  };

  pieColors = [{
    borderColor: [
      this.colors.byName('info'),
      this.colors.byName('yellow'),
      this.colors.byName('purple'),
      this.colors.byName('warning'),
      this.colors.byName('danger'),
      this.colors.byName('inverse'),
      this.colors.byName('pink'),
      this.colors.byName('green'),
      this.colors.byName('gray-darker'),
      this.colors.byName('primary')
    ],
    backgroundColor: [
      this.colors.byName('info'),
      this.colors.byName('yellow'),
      this.colors.byName('purple'),
      this.colors.byName('warning'),
      this.colors.byName('danger'),
      this.colors.byName('inverse'),
      this.colors.byName('gray-darker'),
      this.colors.byName('green'),
      this.colors.byName('pink'),
      this.colors.byName('primary')
    ],
  }];

  pieOptions = {
    responsive: true,
    legend: {
      display: true,
      labels: {
          fontColor: 'rgb(255, 99, 132)'
      }
    }
  };
  menuItems: any;
  categories: any;
  orders: Array<any>;
  order: any;
  orderstats = {
    'On the Way': 0,
    'Pending': 0,
    'Accepted': 0,
    'Cancelled': 0,
    'Delivered': 0
  };
  ordersData: AngularFireList<any>;
  menuItemsData: AngularFireList<any>;
  datePipeEn: DatePipe = new DatePipe('en-US');
  isAdmin = false;

  constructor(private colors: ColorsService, private http: HttpClient, private router: Router, public af: AngularFireDatabase, public afa: AngularFireAuth, @Inject(LOCALE_ID) private _locale: string, public userService: UserService) {
    let currentuser = this.userService.getCurrentUser();
    const userID = currentuser.uid;
    this.isAdmin = this.userService.isAdmin();
    if (currentuser.role == 'admin') {
      this.menuItemsData = af.list('/menuItems', ref => ref.orderByChild('sellerid')); // Get all menu items.
      this.ordersData = af.list('/suborders', ref => ref.orderByChild('sellerId'));
    } else {
      this.menuItemsData = af.list('/menuItems', ref => ref.orderByChild('sellerid').equalTo(userID));
      this.ordersData = af.list('/suborders', ref => ref.orderByChild('sellerId').equalTo(userID));
    }

    // Get Menu Items
    this.menuItemsData.valueChanges().subscribe((res) => {
      this.menuItems = res.length || 0;
    });

    // Get Categories detail
    af.list('/categories').valueChanges().subscribe((res) => {
      this.categories = res.length;
    });

    // Get orders.
  }

  ngOnInit() {
    this.ordersData.valueChanges().subscribe((res2) => {
      this.orders = res2;
      this.order = res2.length;
      let lastDate = 0;
      console.log();
      let x;
      const dayDuration = 86400000;
      x = 7;
      // Find the latest order date.
      console.log(this.orderstats);
      for (let o = 0; o <= res2.length - 1; o++) {
        this.orderstats[res2[o].status] = (this.orderstats[res2[o].status] || 0) + 1;
        if (lastDate < res2[o].createdAt) {
          lastDate = res2[o].createdAt;
        }
      }
      console.log(this.orderstats);
      lastDate -= 6 * dayDuration;
      for (let j = 0; j < x; j++) {
        this.barData.labels.push(this.datePipeEn.transform(lastDate, 'dd MMMM'));
        lastDate += dayDuration;
      }
      for (let i = 0; i <= this.barData.labels.length - 1; i++) {
        let dayValue = 0;
        for (let j = 0; j <= this.orders.length - 1; j++) {
          const orderDate = this.datePipeEn.transform(this.orders[j].createdAt, 'dd MMMM');
          if (orderDate === this.barData.labels[i]) {
            dayValue += this.orders[j].grandTotal;
          }
        }
        this.barData.datasets[0].data.push(dayValue);
      }
    });

    // Pie Chart
    /*this.af.list('/categories').snapshotChanges().subscribe((res: any[]) => {
      const _categories = [];
      let category = [];
        res.forEach((m: any) => {
            const _m = m.payload.toJSON();
            _m.$key = m.key;
            _categories.push(_m);
        });
        category = _categories;
      this.af.list('/menuItems').valueChanges().subscribe((menuitem: any[]) => {
        for (let i = 0; i <= category.length - 1; i++) {
          let x = 0;
          let quantity = 0;
          for (let j = 0; j <= menuitem.length - 1; j++) {
            if (menuitem[j].category === category[i].$key) {
              x++;
              quantity++;
            }
          }
          this.pieData.labels.push(category[i].title);
          this.pieData.datasets[0].data.push(quantity);
        }
      });
    });*/
  }


  navigateTo(pageurl: string) {
    this.router.navigate([pageurl]);
  }

  colorByName(name) {
    return this.colors.byName(name);
  }

}
