import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import { UserService } from 'app/core/user/user.service';
import * as moment from 'moment';
import * as jstz from 'jstz';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
isAdmin = false;
currentuseremail = '';
users: Array<any>;
public rowsOnPage = 10;
public sortBy = "name";
public sortOrder = "asc";
authuserlist: any;
usersData: any[];
tz = 'UTC';
filterQuery = "";
  constructor(public af: AngularFireDatabase, public router: Router, public userService: UserService ) {
    let currentuser = this.userService.getCurrentUser();
    this.tz = (jstz.determine().name() || 'UTC');
    console.log(currentuser);
    if (this.authuserlist == null){
      this.userService.getUserList().then((_authusers) => {
        this.authuserlist = _authusers;
        console.log(_authusers);
        const usersDataref = af.list('/users');
        let usersDataSubscribe = usersDataref.snapshotChanges().subscribe((res: any[]) => {
          const _usersData = [];
          res.forEach((m: any) => {
              const _m = m.payload.toJSON();
              if (_m.role == 'User') {
                _m.$key = m.key;
                _m.lastSigninTime = {
                  asStr: this.authuserlist[m.key].lastSignInTime || '',
                  asDt: this.authuserlist[m.key].dtlastSignInTime || 0,
                  asLocalHuman: moment.utc(this.authuserlist[m.key].dtlastSignInTime).local().fromNow(),
                  asLocalDtStr: moment.utc(this.authuserlist[m.key].dtlastSignInTime).local().format("dddd, MMMM Do YYYY, h:mm:ss a") 
                },
                _m.creationTime = {
                  asStr: this.authuserlist[m.key].creationTime || '',
                  asDt: this.authuserlist[m.key].dtcreationTime || 0,
                  asLocalHuman: moment.utc(this.authuserlist[m.key].dtcreationTime).local().fromNow(),
                  asLocalDtStr: moment.utc(this.authuserlist[m.key].dtcreationTime).local().format("dddd, MMMM Do YYYY, h:mm:ss a") 
                },
                _m.providers = this.authuserlist[m.key].providers,
                _usersData.push(_m);  
              }
          });
          this.usersData = _usersData;
          console.log(_usersData);
          usersDataSubscribe.unsubscribe();
        });
      });
    }
    this.currentuseremail = currentuser.email;
    this.isAdmin = this.userService.isAdmin();
  }

  usersShow(key) {
     this.router.navigate(['/users/viewUser', key]);
  }

  usersDelete(key: any) {
    Swal.fire({
            title: 'Are you sure?',
            text: 'Your will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            preConfirm: function() {
              return new Promise<void>(function(resolve) {
                setTimeout(function() {
                  resolve()
                }, 2000)
              })
            }
      }).then((result: any) => {
        if (result.value) {
          const usersDataref = this.af.list('/users');
          usersDataref.remove(key).then((res) => {
              Swal.fire('Deleted!', 'User Deleted Successfully!', 'success');
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
           Swal.fire('Cancelled', 'Your data is safe :)', 'error');
        }
      });
  }

}
