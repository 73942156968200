<tabset [justified]="true">
    <tab>
        <ng-template tabHeading>
            <em class="icon-equalizer fa-lg"></em>
        </ng-template>
        <h3 class="text-center text-thin" [innerHTML]="'offsidebar.setting.SETTINGS' | translate"></h3>
        <div class="p">
            <h4 class="text-muted text-thin">Themes</h4>
            <div class="table-grid mb">
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="A" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-info"></span>
                            <span class="color bg-info-light"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="B" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-green"></span>
                            <span class="color bg-green-light"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="C" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-purple"></span>
                            <span class="color bg-purple-light"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="D" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-danger"></span>
                            <span class="color bg-danger-light"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="table-grid mb">
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="E" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-info-dark"></span>
                            <span class="color bg-info"></span>
                            </span>
                            <span class="color bg-gray-dark"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="F" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-green-dark"></span>
                            <span class="color bg-green"></span>
                            </span>
                            <span class="color bg-gray-dark"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="G" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-purple-dark"></span>
                            <span class="color bg-purple"></span>
                            </span>
                            <span class="color bg-gray-dark"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="H" />
                            <span class="icon-check"></span>
                            <span class="split">
                  <span class="color bg-danger-dark"></span>
                            <span class="color bg-danger"></span>
                            </span>
                            <span class="color bg-gray-dark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="p">
            <h4 class="text-muted text-thin">Layout</h4>
            <div class="clearfix">
                <p class="pull-left">Fixed</p>
                <div class="pull-right">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="settings.layout.isFixed" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="pull-left">Boxed</p>
                <div class="pull-right">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="settings.layout.isBoxed" />
                        <span></span>
                    </label>
                </div>
            </div>
            <!--
    <div class="clearfix">
      <p class="pull-left">RTL</p>
      <div class="pull-right">
         <label class="switch">
            <input type="checkbox" [(ngModel)]="layoutRTL"/>
            <span></span>
         </label>
      </div>
    </div>
    -->
        </div>
        <div class="p" *ngIf="!settings.layout.horizontal">
            <h4 class="text-muted text-thin">Aside</h4>
            <div class="clearfix">
                <p class="pull-left">Collapsed</p>
                <div class="pull-right">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="settings.layout.isCollapsed" [disabled]="settings.layout.isCollapsedText" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="pull-left">Collapsed Text</p>
                <div class="pull-right">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="settings.layout.isCollapsedText" [disabled]="settings.layout.isCollapsed" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="pull-left">Float</p>
                <div class="pull-right">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="settings.layout.isFloat" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="pull-left">Hover</p>
                <div class="pull-right">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="settings.layout.asideHover" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="pull-left">Show Scrollbar</p>
                <div class="pull-right">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="settings.layout.asideScrollbar" />
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="p">
            <h4 class="text-muted text-thin">Language</h4>
            <select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
                <option [value]="lang.code" *ngFor="let lang of getLangs()">{{lang.text}}</option>
            </select>
        </div>
    </tab>
    <tab>
        <ng-template tabHeading>
            <em class="icon-user fa-lg"></em>
        </ng-template>
        <h3 class="text-center text-thin">Connections</h3>
        <ul class="nav">
            <!-- START list title-->
            <li class="p">
                <small class="text-muted">ONLINE</small>
            </li>
            <!-- END list title-->
            <li>
                <!-- START User status-->
                <a href="#" class="media-box p mt0">
                    <span class="pull-right">
                                 <span class="circle circle-success circle-lg"></span>
                    </span>
                    <span class="pull-left">
                                 <!-- Contact avatar-->
                                 <!--<img src="assets/img/user/05.jpg" alt="Image" class="media-box-object img-circle thumb48">-->
                              </span>
                    <!-- Contact info-->
                    <span class="media-box-body">
                                 <span class="media-box-heading">
                                    <strong>Juan Sims</strong>
                                    <br>
                                    <small class="text-muted">Designeer</small>
                                 </span>
                    </span>
                </a>
                <!-- END User status-->
                <!-- START User status-->
                <a href="#" class="media-box p mt0">
                    <span class="pull-right">
                                 <span class="circle circle-success circle-lg"></span>
                    </span>
                    <span class="pull-left">
                                 <!-- Contact avatar-->
                                 <!--<img src="assets/img/user/06.jpg" alt="Image" class="media-box-object img-circle thumb48">-->
                              </span>
                    <!-- Contact info-->
                    <span class="media-box-body">
                                 <span class="media-box-heading">
                                    <strong>Maureen Jenkins</strong>
                                    <br>
                                    <small class="text-muted">Designeer</small>
                                 </span>
                    </span>
                </a>
                <!-- END User status-->
                <!-- START User status-->
                <a href="#" class="media-box p mt0">
                    <span class="pull-right">
                                 <span class="circle circle-danger circle-lg"></span>
                    </span>
                    <span class="pull-left">
                                 <!-- Contact avatar-->
                                 <!--<img src="assets/img/user/07.jpg" alt="Image" class="media-box-object img-circle thumb48">-->
                              </span>
                    <!-- Contact info-->
                    <span class="media-box-body">
                                 <span class="media-box-heading">
                                    <strong>Billie Dunn</strong>
                                    <br>
                                    <small class="text-muted">Designeer</small>
                                 </span>
                    </span>
                </a>
                <!-- END User status-->
                <!-- START User status-->
                <a href="#" class="media-box p mt0">
                    <span class="pull-right">
                                 <span class="circle circle-warning circle-lg"></span>
                    </span>
                    <span class="pull-left">
                                 <!-- Contact avatar-->
                                 <!--<img src="assets/img/user/08.jpg" alt="Image" class="media-box-object img-circle thumb48">-->
                              </span>
                    <!-- Contact info-->
                    <span class="media-box-body">
                                 <span class="media-box-heading">
                                    <strong>Tomothy Roberts</strong>
                                    <br>
                                    <small class="text-muted">Designer</small>
                                 </span>
                    </span>
                </a>
                <!-- END User status-->
            </li>
            <!-- START list title-->
            <li class="p">
                <small class="text-muted">OFFLINE</small>
            </li>
            <!-- END list title-->
            <li>
                <!-- START User status-->
                <a href="#" class="media-box p mt0">
                    <span class="pull-right">
                                 <span class="circle circle-lg"></span>
                    </span>
                    <span class="pull-left">
                                 <!-- Contact avatar-->
                                 <!--<img src="assets/img/user/09.jpg" alt="Image" class="media-box-object img-circle thumb48">-->
                              </span>
                    <!-- Contact info-->
                    <span class="media-box-body">
                                 <span class="media-box-heading">
                                    <strong>Lawrence Robinson</strong>
                                    <br>
                                    <small class="text-muted">Developer</small>
                                 </span>
                    </span>
                </a>
                <!-- END User status-->
                <!-- START User status-->
                <a href="#" class="media-box p mt0">
                    <span class="pull-right">
                                 <span class="circle circle-lg"></span>
                    </span>
                    <span class="pull-left">
                                 <!-- Contact avatar-->
                                 <!--<img src="assets/img/user/10.jpg" alt="Image" class="media-box-object img-circle thumb48">-->
                              </span>
                    <!-- Contact info-->
                    <span class="media-box-body">
                                 <span class="media-box-heading">
                                    <strong>Tyrone Owens</strong>
                                    <br>
                                    <small class="text-muted">Designer</small>
                                 </span>
                    </span>
                </a>
                <!-- END User status-->
            </li>
            <li>
                <div class="p-lg text-center">
                    <!-- Optional link to list more users-->
                    <a href="#" title="See more contacts" class="btn btn-purple btn-sm">
                        <strong>Load more..</strong>
                    </a>
                </div>
            </li>
        </ul>
        <!-- Extra items-->
        <div class="p">
            <p>
                <small class="text-muted">Tasks completion</small>
            </p>
            <div class="progress progress-xs m0">
                <div role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" class="progress-bar progress-bar-success progress-80">
                    <span class="sr-only">80% Complete</span>
                </div>
            </div>
        </div>
        <div class="p">
            <p>
                <small class="text-muted">Upload quota</small>
            </p>
            <div class="progress progress-xs m0">
                <div role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" class="progress-bar progress-bar-warning progress-40">
                    <span class="sr-only">40% Complete</span>
                </div>
            </div>
        </div>
    </tab>
</tabset>
