import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'app/core/user/user.service';
@Injectable()
export class LoginService {
  currentUser: any = {};
  
  constructor(public af: AngularFireAuth, public userservice: UserService) { 
    this.currentUser = this.userservice.getCurrentUser();
  }


  isAuthenticated() {
    this.currentUser = this.userservice.getCurrentUser();
    const user = this.currentUser !== null;
    console.log('current user: ' + user);
    if (user) {
      return true;
    } else {
      return false;
    }
  }
}

