<div class="content-heading">View Category
 <small><a [routerLink]=" ['/home'] ">Dashboard</a> ->
        <a [routerLink]="['/menu/manageItems']"> Menu Items </a>->
            View Menu Item
 </small>
</div>
<div class="col-masonry" *ngIf="menuDetails != null">
        <div class="panel b m0">
            <div class="panel-heading">
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-4 text-center mb-lg">
                        <a>
                            <img class="img-responsive img-thumbnail" src="{{menuDetails.thumb}}" alt="Image" />
                        </a>
                    </div>
                    <div class="col-lg-8">
                        <div class="col-lg-3">
                            <h4 class="media-heading custom">Name</h4>
                        </div>
                        <div class="col-lg-5">
                            <h2><p>{{menuDetails.title}}</p></h2>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="col-lg-3">
                            <h4 class="media-heading">Description</h4>
                        </div>
                        <div class="col-lg-5">
                            <p>{{menuDetails.description}}</p>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="col-lg-3">
                            <h4 class="media-heading">Is Active</h4>
                        </div>
                        <div class="col-lg-5">
                            <p>{{menuDetails.isActive}}</p>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="col-lg-3">
                            <h4 class="media-heading">Preparation Time</h4>
                        </div>
                        <div class="col-lg-5">
                            <p>{{menuDetails.preptimehrs}}</p>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="col-lg-3">
                            <h4 class="media-heading">Price</h4>
                        </div>
                        <div class="col-lg-5">
                            <p>{{menuDetails.price.value}}</p>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="col-lg-3">
                            <h4 class="media-heading">Portion Size</h4>
                        </div>
                        <div class="col-lg-5">
                            <p>{{menuDetails.price.pname}}</p>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="col-lg-3">
                            <h4 class="media-heading">Tags</h4>
                        </div>
                        <div class="col-lg-5">
                            <p>{{menuDetails.strtags}}</p>
                        </div>
                    </div>

                    <div class="col-lg-8" style="display: none;">
                        <div class="col-lg-3">
                            <h4 class="media-heading">Category</h4>
                        </div>
                        <div class="col-lg-5">
                            <p>{{menuDetails.categoryTitle}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-body">
            </div>
        </div>
    </div>