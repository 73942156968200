<div class="wrapper">
    <div class="block-center mt-xl wd-xl">
        <!-- START panel-->
        <div class="panel panel-dark panel-flat">
            <div class="panel-heading text-center">
                <a href="#">
                    <img class="block-center img-rounded img-responsive" src="assets/img/logo.png" alt="Image" />
                </a>
            </div>
            <div class="panel-body">
                <p class="text-center pv">SIGNUP TO GET INSTANT ACCESS.</p>
                <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="registerForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                   <div class="form-group has-feedback">
                        <label class="text-muted">Name</label>
                        <input class="form-control" type="text" name="name" placeholder="Enter Your Name" autocomplete="off" formControlName="name" required="" />
                        <span class="fa fa-envelope form-control-feedback text-muted"></span>
                    </div>
                    <div class="form-group has-feedback">
                        <label class="text-muted">Email address</label>
                        <input class="form-control" type="email" name="account_email" placeholder="Enter email" autocomplete="off" formControlName="email" required="" />
                        <span class="fa fa-envelope form-control-feedback text-muted"></span>
                        <span class="text-danger" *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field is required</span>
                        <span class="text-danger" *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field must be a valid email address</span>
                    </div>
                     <div class="form-group has-feedback">
                        <label class="text-muted">Mobile No</label>
                        <input class="form-control" type="text" name="mobileNo" placeholder="Enter Your Mobile No" autocomplete="off" formControlName="mobileNo" required="" />
                        <span class="fa fa-envelope form-control-feedback text-muted"></span>
                    </div>
                    <div class="form-group has-feedback">
                        <div class="form-group has-feedback">
                            <label class="text-muted">Password</label>
                            <input class="form-control" id="id-password" type="password" name="password" formControlName="password">
                            <span class="text-danger" *ngIf="valForm.controls['password'].hasError('pattern') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">Password length should be between 6 and 15 characters long.</span>
                        </div>
                    </div>
                    <button class="btn btn-block btn-primary mt-lg"  [disabled]="!valForm.valid" type="submit">Create account</button>
                </form>
                <!-- <div class="alert alert-danger text-center"></div> -->
               <p class="pt-lg text-center">Have an account?</p><a class="btn btn-block btn-default" [routerLink]="'/login'">Login</a>
            </div>
        </div>
        <!-- END panel-->
        <div class="p-lg text-center">
            <span>&copy;</span>
            <span>{{ settings.app.year }}</span>
            <span>-</span>
            <span>{{ settings.app.name }}</span>
            <br/>
            <span>{{ settings.app.description }}</span>
        </div>
    </div>
</div>
