<div class="content-heading"> Update Business Bank Details
    <small style="color: black; margin-top: 5px;">
        <a [routerLink]=" ['/home'] " >Dashboard</a> -> <a [routerLink]=" ['/businessinfo/all'] " >Business User</a> ->Add Business Bank Details
    </small>
</div>
<!-- START panel-->
<div class="panel panel-default">
    <div class="panel-heading">Business Details</div>
    <div class="panel-body">
        <form role="form" (ngSubmit)="onAddBusinessBankInfo(f)" #f="ngForm">
            <fieldset>
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Store Name</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="storeName" id="storeName"
                                    [(ngModel)]="razorpayaccount.name" type="text"
                                    placeholder="Seller Name"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Seller Email</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="email" id="email"
                                    [(ngModel)]="razorpayaccount.email" type="email"
                                    placeholder="Seller Email"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Business Name</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="business_name" id="business_name"
                                    [(ngModel)]="razorpayaccount.account_details.business_name" type="text"
                                    placeholder="Business Name"/>
                            </div>
                        </div>
                    </div>
                </div>

                <br>
                <h4>Razor Pay Account Setup</h4>
                <hr>
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Bank Account IFSC</label>
                    <div class="col-sm-4">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="ifsc_code" id="ifsc_code" maxlength="11" minlength="11"
                                    [(ngModel)]="razorpayaccount.bank_account.ifsc_code" type="text" (change)="lookupIFSC()"
                                    placeholder="Business account IFSC"/>                                
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="ifsc_code2" id="ifsc_code2" maxlength="11" minlength="11"
                                    [(ngModel)]="razorpayaccount.bank_account.ifsc_code2" type="text"
                                    placeholder="ReEnter Business account IFSC"
                                    onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row panel" *ngIf="ifscdetails.BANK.length > 0">
                    <div class="col-sm-2">&nbsp;</div>
                    <div class="col-sm-10" >
                        {{ifscdetails.BANK}} ({{ifscdetails.ADDRESS}})
                    </div>                    
                </div>
                

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Business Type</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="business_type" id="business_type" readonly
                                    [(ngModel)]="razorpayaccount.account_details.business_type" type="text"
                                    placeholder="Business Type"
                                    onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Bank account name (should exactly match as in your bank account name)</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="beneficiary_name" id="beneficiary_name" 
                                    [(ngModel)]="razorpayaccount.bank_account.beneficiary_name" type="text"
                                    placeholder="Business account beneficiary"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Bank account type</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="account_type" id="account_type"  readonly
                                    [(ngModel)]="razorpayaccount.bank_account.account_type" type="text"
                                    placeholder="Business account type"/>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Bank account number</label>
                    <div class="col-sm-4">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="account_number" id="account_number" maxlength="18" minlength="9"
                                    [(ngModel)]="razorpayaccount.bank_account.account_number" type="text"
                                    placeholder="Business account number"
                                    onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="account_number2" id="account_number2" maxlength="18" minlength="9"
                                    [(ngModel)]="razorpayaccount.bank_account.account_number2" type="text"
                                    placeholder="Re-enter Business account number"
                                    onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Terms and Conditions Accepted</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <div style="display: block">                                    
                                    Vegecious <b><u>does not</u></b> store any bank information. The bank information you provide here, will be directly saved in Razor Pay. We only store a reference number provided by Razor Pay for cross reference link.<br>                               
                                    <b>Razor Pay Terms and Conditions</b>: I have read and understood the <a href="https://razorpay.com/terms/" target="_blank">Terms & Conditions</a>, <a href="https://razorpay.com/agreement/" target="_blank">Merchant Agreement</a> and the <a href="https://razorpay.com/privacy/" target="_blank">Privacy Policy</a>. By submitting the form, I agree to abide by the rules at all times. Please review the form before submitting. For any changes after submission, you can write to <a href="https://dashboard.razorpay.com/app/route/accounts/acc_GHOp2pzPNrPlbt#ticket" target="_blank">razorpay support</a>.
                                </div>
                                <div class="form-control">
                                    <input type="checkbox" name="tnc_accepted" id="tnc_accepted" [(ngModel)]="razorpayaccount.tnc_accepted">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row panel">
                    <div class="col-md-12">
                        <button class="btn btn-primary pull-right" [disabled]="IsValidForm()" type="submit">Submit</button>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>
