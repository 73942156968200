import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-add-locations',
    templateUrl: './add-locations.component.html',
    styleUrls: ['./add-locations.component.scss']
})
export class AddLocationsComponent {

    location: any = {
        title: ''
    };
    locationData: AngularFireList<any>;


    constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService) {
        this.locationData = af.list('/locations');
    }

    onSubmitTag(form: NgForm) {
        this.locationData.push(this.location).then((res) => {
            this.router.navigate(['/locations/all']);
            this.toastr.success('Location Added Successfully!', 'Success!');
        });
    }
     cancel() {
       this.router.navigate(['/locations/all']);
    }
}

