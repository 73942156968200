<!-- START Top Navbar-->
<nav class="navbar navbar-expand-lg topnavbar" role="navigation">
    <div class="navbar-header">
        <a class="navbar-brand" href="#/">
            <div class="brand-logo">
                <img class="img-responsive" style="max-height: -webkit-fill-available; width: 250px;" src="assets/img/logo.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-responsive" style="max-height: -webkit-fill-available;" src="assets/img/icon-small.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <span class="navbar-brand businesstitle hidden-xs">{{businesstitle}}</span>
    <ul class="nav navbar-nav">
        <li>
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="hidden-xs" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fa fa-navicon" style="color: black;"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="visible-xs sidebar-toggle" (click)="settings.layout.asideToggled =! settings.layout.asideToggled">
                <em class="fa fa-navicon"  style="color: black;"></em>
            </a>
        </li>                
    </ul>
 </nav>
 <!-- END Top Navbar-->
    