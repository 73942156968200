<div class="content-heading"> Add Menu Item
    <small><a [routerLink]=" ['/home'] ">Dashboard</a> ->
        <a [routerLink]="['/menu/manageItems']"> Menu Items </a>->
        Add Menu Item
    </small>
</div>
<!-- START panel-->
<div class="panel panel-default">
    <div class="panel-heading">Item Details</div>
    <div class="panel-body">
        <form (ngSubmit)="onSubmitMainItems(f)" #f="ngForm">
            <fieldset>
                <div class="row panel" *ngIf="isAdmin">
                    <label class="col-sm-2 control-label custom" for="title">Seller</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <ng-select
                                [items]="allsellers$"
                                name="sellers"
                                [multiple]="false"
                                [closeOnSelect]="true"
                                (change)="onSellerChange($event)"
                                [searchable]="true"
                                bindLabel="storeName"
                                placeholder="Select Seller"
                                [(ngModel)]="selectedSeller">
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom" for="title">Item*</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="title" id="title" [(ngModel)]="menuItems.title"
                                    type="text" required placeholder="Item Name"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Item Description
                    </label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <textarea class="form-control" name="description" id="description" placeholder="Please describe your Menu item here. Include ingredients information, any allergy information, any order/availability restrictions (for e.g. only available in weekend)."
                                        [(ngModel)]="menuItems.description" required="" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="padding: 15px; border: 2px solid lightgray">
                    <div class="row panel">
                        <label class="col-sm-2 control-label custom">Portion Size</label>
                        <div class="col-sm-10">
                            <div class="panel">
                                <div class="panel-body mh0">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <div class="col-md-5">
                                                <input class="form-control" type="text" name="price-pname" id="price-pname" [(ngModel)]="menuItems.price.pname" placeholder="Base Price Label"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row panel">
                        <label class="col-sm-2 control-label custom">Price ({{currencysymbol}}) *</label>
                        <div class="col-sm-10">
                            <div class="panel">
                                <div class="panel-body mh0">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <div class="col-md-5">
                                                <input class="form-control" type="number" name="price-value" id="price.value" [(ngModel)]="menuItems.price.value" placeholder="Product base Price">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row panel">
                        <label  class="col-sm-2 control-label custom">Quantity Range</label>
                        <div class="col-sm-10">
                            <div class="panel">
                                    <div class="panel-body mh0">                        
                                        <div>
                                            <label class="control-label custom">Min Qty: </label>
                                            <input class="form-control" type="number" name="minqty" id="minqty" [(ngModel)]="menuItems.quantityrange.min" placeholder="Min Qty"/>
                                        </div>
                                        <div>
                                            <label class="control-label custom">Max Qty: </label>
                                            <input class="form-control" type="number" name="maxqty" id="maxqty" [(ngModel)]="menuItems.quantityrange.max" placeholder="Max Qty"/>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
    
                </div>
                
                <div class="row panel" style="display: none;">
                    <label class="col-sm-2 control-label custom">Category</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <select class="form-control" name="category" id="category" [(ngModel)]="menuItems.category.id">
                                    <option *ngFor="let category of categories" value="{{category.$key}}">
                                        {{category.title}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Add-ons</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <div class="row">
                                    <div class="col-md-11 added-block"
                                        *ngFor="let extra of menuItems.extraOptions; let i = index" [attr.data-index]="i">
                                        <div class="col-md-5">
                                            <input class="form-control" type="text" name="name-{{i}}" id="name"
                                                [(ngModel)]="extra.name" placeholder="Add-on Item Name"/>
                                        </div>
                                        <div class="col-md-5">
                                            <input class="form-control" type="number" name="selected-{{i}}" id="selected"
                                                [(ngModel)]="extra.value" placeholder="Add-on Item Price"/>
                                        </div>
                                        <div class="col-md-1">
                                            <button class="btn btn-danger" type="button" (click)="removeChoice()">
                                                <em class="fa fa-trash"></em>
                                            </button>
                                        </div>
                                    </div><!--col-->
                                    <div class="col-md-1 added-block">
                                        <button class="btn btn-sm btn-info" type="button" (click)="addNewChoice()">
                                            <em class="fa fa-plus"></em>
                                        </button>
                                    </div>
                                </div><!--row-->

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel" style="display: none;">
                    <label class="col-sm-2 control-label custom">Extended Price</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <div class="row">
                                    <div class="col-md-11 added-block" *ngFor="let choice of menuItems.additionalprice; let i = index"
                                        [attr.data-index]="i">
                                        <div class="col-md-5">
                                            <input class="form-control" type="text" name="pname-{{i}}" id="pname"
                                                [(ngModel)]="choice.pname" placeholder="Product Variance (size)"/>
                                        </div>
                                        <div class="col-md-5">
                                            <input class="form-control" type="number" name="value-{{i}}" id="value"
                                                [(ngModel)]="choice.value" placeholder="Product Price">
                                        </div>
                                        <div class="col-md-1">
                                            <button class="btn btn-danger" type="button" (click)="removeaddtlPrice()">
                                                <em class="fa fa-trash"></em>
                                            </button>
                                        </div>
                                    </div><!--col-->
                                    <div class="col-md-1 added-block">
                                        <button class="btn btn-sm btn-info" type="button" (click)="addNewaddtlPrice()">
                                            <em class="fa fa-plus"></em>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Photo</label>
                    <div class="col-sm-10">
                        <div class="row panel">
                            <div class="col-md-4">
                                <div class="panel-body mh0 fileUpload btn btn-primary choose-btn">
                                    <span> <i class="fa fa-upload" aria-hidden="true"></i> &nbsp; Upload</span>
                                    <input type="file" class="upload" accept="image/*" (change)="readUrl($event)">
                                </div>
                            </div><!--col-->
                            <div class="col-md-6" [hidden]="menuItems.thumb ==''">
                                <img class="media-object img-thumbnail thumb96" [src]="menuItems.thumb" alt="Image"/>
                            </div><!--COL-->
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Active</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <div class="form-control">
                                    <input type="checkbox" name="isActive" id="isActive" [(ngModel)]="menuItems.isActive">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Lead time (hrs): </label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                    <input class="form-control" type="number" name="preptimehrs" id="preptimehrs" [(ngModel)]="menuItems.preptimehrs" placeholder="Time to prepare."/>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Offer Percentage
                    </label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" type="number" name="offerPercentage" id="offerPercentage"
                                    [(ngModel)]="menuItems.offer.offerPercentage" required="" rows="3">
                            </div>
                        </div>
                    </div>
                </div>
            
                
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Tags (Max: {{maxtags}})
                    </label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <div style="width: 300px; margin-bottom: 20px;">
                                    <ng-select
                                        [items]="alltags$ | async"
                                        name="tags"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                        [searchable]="true"
                                        bindLabel="text"
                                        bindvalue="text"
                                        maxSelectedItems="{{maxtags}}"
                                        placeholder="Select tags"
                                        [(ngModel)]="selectedTags">
                                    </ng-select>
                                    <!--<ng-select [allowClear]="true"
                                        [multiple]="true"
                                        [active]="selectedTags"
                                        [items]="alltags$ | async"
                                        [disabled]="disabled"
                                        (data)="refreshValue($event)"
                                        (selected)="selected($event)"
                                        (removed)="removed($event)"
                                        (typed)="typed($event)"
                                        placeholder="Select Tags">
                                    </ng-select>-->
                                  </div>                 
                            </div>
                        </div>
                    </div>
                </div>

                
                
                <div class="row panel">
                    <div class="col-md-12">
                        <button class="btn btn-danger pull-right s-n-btn" (click)="cancel()">Cancel</button>
                        <button class="btn btn-primary pull-right s-n-btn" type="submit" [disabled]="issubmitting">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Submit
                        </button>                      
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>
