import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {
  userDetails: any= {};
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase, public authentication: AngularFireAuth, public toastr: ToastrService) {
    this.af.object('/users');
  }

  onAddUsers(form: NgForm) {
    console.log('Users Data : ' + JSON.stringify(this.userDetails));
    this.authentication.createUserWithEmailAndPassword(this.userDetails.email, this.userDetails.password)
      .then(success => {
        this.af.object('/users/' + success.user.uid).update({
        email: this.userDetails.email,
        name: this.userDetails.name,
        mobileNo: this.userDetails.mobileNo,
        role: 'chef'
      }).then((res) => {
        console.log('Success');
        this.toastr.success('User added Successfully!', 'Success!');
        this.router.navigate(['/users/manageUsers']);
      },
      (err) => {
          console.log(err);
          this.toastr.error('Create Account Failed!', err);
      });
    },
    (err) => {
        console.log(err);
        this.toastr.error('Create Account Failed!', err);
    });
  }
  
  
  cancel() {
    this.router.navigate(['/users/manageUsers']);
  }
}
