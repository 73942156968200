import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';
import { Configs } from '../../../config';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { UserService } from 'app/core/user/user.service';


@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent {

    siteVal: any;
    currency: '';
    isAdmin = false;
    menuItems: Array<any>;
    allitems: Array<any>;
    menuItemsData: AngularFireList<any>;

    constructor(public af: AngularFireDatabase, public afa: AngularFireAuth, public router: Router, public toastr: ToastrService, public userService: UserService) {
        this.currency = Configs[environment.envname].Currency.symbol;
        let currentuser = this.userService.getCurrentUser();
        this.isAdmin = this.userService.isAdmin();
       if (this.isAdmin) {
            console.log('Getting all items.')
            this.menuItemsData = af.list('/menuItems', ref => ref.orderByChild('sellerid')); // Get all menu items.
        } else {
            console.log('Getting only current seller items.')
            this.menuItemsData = af.list('/menuItems', ref => ref.orderByChild('sellerid').equalTo(currentuser.uid));
        }
        
        this.menuItemsData.snapshotChanges().subscribe((res: any[]) => {
            const _menuitems = [];
            res.forEach((m: any) => {
                const _m = m.payload.toJSON();
                _m.$key = m.key;
                console.log(JSON.stringify(_m));
                _m.titleuppercase = _m.title.toUpperCase();
                _menuitems.push(_m);
            });
            this.menuItems = _menuitems;
            this.allitems = _menuitems;
            console.log('Total menu items: ' + this.menuItems.length);
        });
    }


    getMenuItems(ev: any) {
        const query = ev;
        console.log('searching: ' + query);
        let _srcitem = query.toUpperCase();
    
        this.menuItems = this.allitems.filter((data) => {
            return (data.titleuppercase.indexOf(_srcitem) > -1)
        });  
    }

    menuItemShow(key) {
        this.router.navigate(['/menu/viewItems', key]);
    }

    menuItemEdit(key) {
        this.router.navigate(['/menu/editItems', key]);
    }

    menuItemDelete(key: any) {
        Swal.fire({
                title: 'Are you sure?',
                text: 'Your will not be able to recover this data!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!'
        }).then((result: any) => {
            if (result.value) {
                this.menuItemsData.remove(key).then((res) => {
                    Swal.fire('Deleted!', 'Menu Item Deleted Successfully!', 'success');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelled', 'Your data is safe :)', 'error');
            }
        });
    }
}
