import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
const screenfull = require('screenfull');
// const browser = require('jquery.browser');
declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { Router} from '@angular/router';
import { SellerService } from 'app/core/seller/seller.service';
import { Configs } from '../../config';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    businesstitle = '';
    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    currentenv = environment.envname;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', {static: false}) fsbutton;  // the fullscreen button

    constructor(private router: Router, public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService, public seller: SellerService, public afAuth: AngularFireAuth) {
        this.menuItems = menu.getMenu();
        const _seller: any = this.seller.getSeller();
        this.businesstitle = (_seller == null) ? '' : _seller.business.storeName;
        console.log('business name is ' + this.businesstitle);
    }

    ngOnInit() {
        this.isNavSearchVisible = false;
        /*if (browser.msie) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }*/
    }

    logout() {
        localStorage.clear();
        this.menu.clearMenu();
        this.afAuth.signOut().then(() => {
            this.router.navigate(['/login']);
         });
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
    }

    toggleCollapsedSideabar() {
        this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
    }

    isCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }

    toggleFullScreen(event) {

        if (screenfull.enabled) {
            screenfull.toggle();
        }
        // Switch icon indicator
        const el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
            el.children('em').removeClass('fa-expand').addClass('fa-compress');
        } else {
            el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
    }
}
