<div class="content-heading">Chefs List
    <div class="pull-right">
        <label class="file-upload">
            <a [routerLink]="'/chefs/addChef'">
                <button class="btn btn-warning"><em class="fa fa-plus"></em>&nbsp;&nbsp; Add New Chef</button>
            </a>
        </label>
    </div>
    <small><a [routerLink]=" ['/home'] ">Dashboard</a> -> Chef Detail</small>
</div>
<!-- Zero Configuration-->
<div class="panel panel-default">
    <div class="panel-heading">All Chefs Information</div>
    <div class="panel-body">
        <div class="table-responsive customTable" *ngIf="(usersData != null) && (usersData.length > 0)">
            <table class="table table-striped" [mfData]="usersData  | dataFilter : filterQuery" #mf="mfDataTable" [mfRowsOnPage]="rowsOnPage" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
                <thead>
                    <tr>
                        <th class="thsearch text-center">
                            <mfDefaultSorter by="name">Name</mfDefaultSorter>
                        </th>
                        <th class="thsearch text-center">
                            <mfDefaultSorter by="email">Email</mfDefaultSorter>
                        </th>
                        <th class="thsearch text-center">
                            <mfDefaultSorter by="mobileNo">Mobile Number</mfDefaultSorter>
                        </th>
                        <th class="thsearch text-center">
                            <mfDefaultSorter by="role">User Role</mfDefaultSorter>
                        </th>
                        <th class="thsearch text-center">
                            <mfDefaultSorter by="creationTime.asDt">Account Created</mfDefaultSorter>
                        </th>
                        <th class="thsearch text-center">
                            <mfDefaultSorter by="lastSigninTime.asDt">Last Login</mfDefaultSorter>
                        </th>
                        <th class="thnosearch  text-center">
                            View
                        </th>
                        <th class="thnosearch  text-center">
                            Delete
                        </th>

                        <th class="thnoitem"></th>
                    </tr>
                    <tr>
                        <th>
                            <input class="form-control" [(ngModel)]="filterQuery" placeholder="search by name"/>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" *ngFor="let item of mf.data" [class.chef]="item.role=='chef'">
                        <td>
                            {{item.name}} <span *ngIf="item.isFeaturedChef">*</span>
                            (<i 
                                *ngFor="let provider of item.providers" 
                                class="fa providericon" 
                                [class.fa-google]="provider=='google.com'"
                                [class.fa-facebook]="provider=='facebook.com'"
                                [class.fa-apple]="provider=='apple.com'"
                                [class.fa-envelope]="provider=='password'"
                            ></i>)

                        </td>
                        <td>{{item.email}}</td>
                        <td>{{item.mobileNo}}</td>
                        <td>{{item.role}}</td>
                        <td>{{item.creationTime.asLocalHuman}}</td>
                        <td>{{item.lastSigninTime.asLocalHuman}}</td>
                        <td>
                            <button class="btn btn-sm btn-default" type="button" (click)="chefShow(item.$key)"><em
                            class="fa fa-eye"></em></button>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-danger" *ngIf="item.email !== currentuseremail" type="button" (click)="chefDelete(item.$key)">
                                <em class="fa fa-trash-o"></em>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="5">
                            <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15,100]"></mfBootstrapPaginator>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>