import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataFilterPipe } from './data-filter.pipe';
import { FirebaseimageurlPipe } from './firebaseimageurl.pipe';

@NgModule({
    imports:        [CommonModule],
    declarations:   [FirebaseimageurlPipe, DataFilterPipe],
    exports:        [FirebaseimageurlPipe, DataFilterPipe],
})

export class ApplicationPipesModule {
  static forRoot() {
     return {
         ngModule: ApplicationPipesModule,
         providers: [],
     };
  }
}
