import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import {Configs} from '../../../../config';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent {
  url: any = '';

categoryDetails: any = {};
categoriesdata: AngularFireObject<any>;
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase, public toastr: ToastrService) {
        this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
        if (Id != null) {
            this.categoriesdata = this.af.object('/categories/' + Id);
            this.categoriesdata.valueChanges().subscribe(
              (response) => {
                this.categoryDetails = response;
              }
            );
          }
       });

       /*this.uploader.onBeforeUploadItem = (item: any) => {
            item.url = this.uploader.options.url;
            console.log('image is going');
            localStorage.setItem('image' , 'image Is going');
            return item;
        };*/
  }

  readUrl(event) {
                 if (event.target.files && event.target.files[0]) {
                 const reader = new FileReader();

               reader.onload = (event2: any) => {
                 this.url = event2.target.result;
                 // this.imageRef = 1;
               };

             reader.readAsDataURL(event.target.files[0]);
             }
           }

    onSubmitCategory(form: NgForm) {
      // this.uploader.uploadAll();
      console.log('statement');
      /*this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        const res: any = JSON.parse(response);
        console.log('statement');
        this.categoriesdata.update({
          title: this.categoryDetails.title,
          description: this.categoryDetails.description,
          thumb: res.url
        }).then((res2) => {
          localStorage.removeItem('image');
          this.router.navigate(['/categories/manageCategories']);
          this.toastr.success('Categories Data Updated Successfully!', 'Success!');
        });
      };*/

      if (localStorage.getItem('image') == null) {
        console.log('if');
        this.categoriesdata.update({
          title: this.categoryDetails.title,
          description: this.categoryDetails.description,
        }).then((res) => {
          this.router.navigate(['/categories/manageCategories']);
          this.toastr.success('Categories Data Updated Successfully!', 'Success!');
        });
       }
    }

    cancel() {
      this.router.navigate(['/categories/manageCategories']);
    }
}
