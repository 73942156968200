import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Configs } from 'app/config';
import { environment } from 'environments/environment';
import { AngularFireStorage } from '@angular/fire/storage';
@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent  {

  url: any= '';
  news: any= {};
  newsData: AngularFireList<any>;
  imageId: string;
  uploadedimage: any = null;

  constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService, private ng2ImgMax: Ng2ImgMaxService, public storage: AngularFireStorage ) {
    this.newsData = af.list('/news');
      // Override onSuccessItem to retrieve the imageId
    /*this.uploader.onAfterAddingFile = (item: any) => {
        item.url = this.uploader.options.url;
        return item;
    };*/
  }

    // Image Preview
  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const newsimagefile = event.target.files[0];
      this.ng2ImgMax.resizeImage(newsimagefile, Configs[environment.envname].menuitems.imagesize.width, Configs[environment.envname].menuitems.imagesize.height).subscribe((imageResized: File) => {
        this.uploadedimage = imageResized;
        const reader = new FileReader();
        reader.onload = (event2: any) => {
          this.url = event2.target.result;
          this.news.thumb = event2.target.result;
          console.log('News Image resized.')
        };
        reader.readAsDataURL(imageResized);
      }, (error) => {
        console.log('😢 Oh no. Image Resize Error!', error);
      });
    }
  }

  // Submit function
  onSubmitNews(form: NgForm) {
    let date = new Date(); 
    let publishtime = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());    

    this.news.thumb = ''; // we are not saving base64;
    const filePath = Configs[environment.envname].newsitems.newsitemsimagesfolder + '/';
    const storageref = this.storage.ref(filePath);

    if (this.uploadedimage == null) {
      const filename = Configs[environment.envname].newsitems.missingnewsitem;
      this.storage.ref(filePath + filename).getDownloadURL().toPromise().then((downloadurl) => {
        this.news.thumb = downloadurl;
        this.news.publishtime = publishtime;
        
        console.log('newsItems' + JSON.stringify(this.news));
          this.newsData.push(this.news).then((res2) => {
            this.toastr.success('News-Items Data Added Successfully!', 'Success!');
            this.router.navigate(['/news/manageNews']);
          }, (err) => {
            this.toastr.error('Error adding news item. Error: ' + JSON.stringify(err) , 'Error!');
          });
        }, (err) => {
          console.log('error getting download url for newsitem: ' + err);
        })
    } else {
      const filename = new Date().getTime() + '_' + this.uploadedimage.name;
      this.storage.upload(filePath + filename, this.uploadedimage, {cacheControl: 'public, max-age=31536000'})
        .then((uploaded) => {
          uploaded.ref.getDownloadURL().then((downloadurl) => {
            this.news.thumb = downloadurl;
            this.news.publishtime = publishtime;
            console.log('newsItems' + JSON.stringify(this.news));
            this.newsData.push(this.news).then((res2) => {
              this.toastr.success('News-Items Data Added Successfully!', 'Success!');
              this.router.navigate(['/news/manageNews']);
            }, (err) => {
              this.toastr.error('Error adding news item. Error: ' + JSON.stringify(err) , 'Error!');
            });
          });
        }, (err) => {
          console.log('error getting download url for newsitem: ' + err);
        });
    }    
  }
  cancel() {
      this.router.navigate(['/news/manageNews']);
  }
}

