<div class="content-heading">Coupons
 <div class="pull-right">
            <label for="image-input" class="file-upload">
              <a [routerLink]="'/coupons/addCoupons'">  <button class="btn btn-success"><em class="fa fa-plus"></em>&nbsp;&nbsp;Add Coupon</button> </a>	
            </label>
 </div>
 <small><a [routerLink]=" ['/home'] ">Dashboard</a> ->
            Coupons
 </small>
</div>
<!-- Zero Configuration-->
<div class="col-sm-12">
<div class="panel panel-default">
    <div class="panel-heading">Manage Coupons</div>
    <div class="panel-body">
        <div class="table-responsive">
            <table datatable="" class="table table-striped">
                <thead>
                    <tr>
                        <th class="wd-md text-center custom">Promo Code</th>
                        <th class="wd-md text-center custom">Offer %</th>
                        <th class="wd-md text-center custom">Flat Discount Amt</th>
                        <th class="wd-md text-center custom">Max Amount (Total Budget)</th>
                        <th class="wd-md text-center custom">Max Amount Per Transaction</th>
                        <th class="wd-md text-center custom">Max Use Count</th>
                        <th class="wd-md text-center custom">Sellers</th>
                        <th class="wd-md text-center custom">Start Date</th>
                        <th class="wd-md text-center custom">End Date</th>
                        <th class="wd-md text-center custom">Amount Used</th>
                        <th class="wd-md text-center custom">Coupon Used</th>
                        <th class="wd-md text-center custom">Rules</th>
                        <th class="wd-md text-center custom">Disc Logic</th>
                       <th class="text-center">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" *ngFor="let coupon of coupons">
                        <td>{{coupon.promocode}}</td>
                        <td>{{coupon.dsctpct}}</td>
                        <td>{{coupon.dsctamt}}</td>
                        <td>{{coupon.amountlimit}}</td>
                        <td>{{coupon.amountlimitpertx}}</td>
                        <td>{{coupon.maxusecount}}</td>
                        <td>{{coupon.sellerstr}}</td>
                        <td>{{coupon.validdates.fromDate.day}}/{{coupon.validdates.fromDate.month}}/{{coupon.validdates.fromDate.year}}</td>
                        <td>{{coupon.validdates.toDate.day}}/{{coupon.validdates.toDate.month}}/{{coupon.validdates.toDate.year}}</td>
                        <td>{{coupon.amountused}}</td>
                        <td>{{coupon.timesused}}</td>
                        <td>{{coupon.rules}}</td>
                        <td>{{coupon.applydiscount}}</td>
                        <td class="text-center">
                            <button class="btn btn-sm btn-default" type="button" (click)="couponDelete(coupon.$key)">
                            <em class="fa fa-trash-o"></em>
                            </button>
                           </td>
                       </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>