import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MenuService {

    menuItems: any[];
    observableMenu: BehaviorSubject<any[]>;

    constructor() {
        this.menuItems = [];
        this.observableMenu= new BehaviorSubject<any[]>(this.menuItems);
    }

    clearMenu() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {        
        var len = items.length;

        for (let i=0; i < len; i++) {
            let item = items[i];
            var len2 = this.menuItems.length;
            if (len2 > 0) {
                let lastcurrentmenuitem = this.menuItems[len2-1];
                if (lastcurrentmenuitem.text === 'Logout') {
                    this.menuItems.splice(len2-1, 0, item);
                } else {
                    this.menuItems.push(item);
                }    
            } else {
                this.menuItems.push(item);
            }

        }
    }

    reloadMenu() {
        this.observableMenu.next(this.menuItems);
    }
    getMenu(): any[] {
        return this.menuItems;
    }

}
