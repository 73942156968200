import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
    settings: any = {
        totalVat: 0,
        totalGST: 10,
        commission: {
            forsellerdlv: 0,
            forvegeciousdlv: 0
        },
        theme: 'chocolatey',
        deliverycharge: 0,
        paymentproviders: {
            useRazorPay: true,
            useCOD: true
        },
        workinghours: {
            starttime: {
                hour: 8,
                minute: 0
            },
            endtime: {
                hour: 20,
                minute: 0
            }
        },
        showFeaturedChefs: true
    };
    settingData: AngularFireObject<any>;

    constructor(public af: AngularFireDatabase, public toastr: ToastrService) {
        this.settingData = af.object('/settings');
        this.settingData.valueChanges().subscribe((res) => {
            console.log('Settings: ' + JSON.stringify(res));
            this.settings = res || { totalVat: 10, totalGST: 10};
            this.settings.paymentproviders = this.settings.paymentproviders || {
                useRazorPay: true,
                useCOD: true
            };
            this.settings.commission = this.settings.commission || {
                forsellerdlv: 0,
                forvegeciousdlv: 0
            };
            this.settings.commission.forsellerdlv = res.commission.forsellerdlv || 0;
            this.settings.commission.forvegeciousdlv = res.commission.forvegeciousdlv || 0;
            this.settings.showFeaturedChefs = (res.showFeaturedChefs == null) ? false : res.showFeaturedChefs; 
            this.settings.deliverycharge = res.deliverycharge || 0;
            this.settings.workinghours = this.settings.workinghours ||
            {
                starttime: {
                    hour: 8,
                    minute: 0
                },
                endtime: {
                    hour: 20,
                    minute: 0
                }
            };
        });
    }

    validateEndTimeIsGreaterThanStart() {
        let starttime = (this.settings.workinghours.starttime.hour * 60) + this.settings.workinghours.starttime.minute;
        let endtime = (this.settings.workinghours.endtime.hour * 60) + this.settings.workinghours.endtime.minute;
        return starttime < endtime;
    }

    onSubmitSetting(form: NgForm) {
        if (this.validateEndTimeIsGreaterThanStart()) {
            let settingstosave = {
                totalVat: 0,
                theme: this.settings.theme || 'chocolatey',
                commission: this.settings.commission || {
                    forsellerdlv: 0,
                    forvegeciousdlv: 0
                },
                totalGST: parseInt(this.settings.totalGST),
                deliverycharge: this.settings.deliverycharge || 0,
                paymentproviders: this.settings.paymentproviders,
                workinghours: this.settings.workinghours,
                showFeaturedChefs: this.settings.showFeaturedChefs
            };
            this.settingData.set(settingstosave).then((res) => {
                this.toastr.success('Settings updated Successfully!', 'Success!');
            });   
        } else {
            this.toastr.error('Start time should be less than end time!', 'Error!');
        }
    }
}
