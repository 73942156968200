import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent  {

    siteVal: any;
    news: Array<any>;
    newsData: AngularFireList<any>;

    constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService) {
        this.newsData = af.list('/news');
        this.newsData.snapshotChanges().subscribe((res: any[]) => {
            const _newsdata = [];
            res.forEach((_n: any) => {
                const _news = _n.payload.toJSON();
                _news.$key = _n.key;
                _newsdata.push(_news);
            });
            this.news = _newsdata;
        });
    }


  getnews(ev: any) {
        const val = ev;
        this.newsData = this.af.list('/news', ref => ref.orderByChild('title')
                                                .startAt(val.charAt(0).toUpperCase() + val.slice(1))
                                                .endAt(val.charAt(0).toUpperCase() + val.slice(1) + '\uf8ff'));
        this.newsData
            .valueChanges().subscribe((data) => {
                this.news = data;
            });


    }


   newsShow(key) {
     this.router.navigate(['/news/viewNews', key]);
  }

   newsEdit(key) {
    this.router.navigate(['/news/editNews', key]);
  }

   menuItemEdit(key) {
    this.router.navigate(['/menu/editItems', key]);
  }

   newsDelete(key: any) {
    Swal.fire({
            title: 'Are you sure?',
            text: 'Your will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            preConfirm: function() {
              return new Promise<void>(function(resolve) {
                setTimeout(function() {
                  resolve()
                }, 2000)
              })
            }
    }).then((result: any) => {
        if (result.value) {
            this.newsData.remove(key).then((res) => {
                Swal.fire('Deleted!', 'News Deleted Successfully!', 'success');
              });
           } else if (result.dismiss === Swal.DismissReason.cancel) {
             Swal.fire('Cancelled', 'Your data is safe :)', 'error');
         }
     });
  }
}
