import { Component } from '@angular/core';
import { Router, ActivatedRoute, RouteConfigLoadStart } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireObject, AngularFireList} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import {Configs} from '../../../../config';
import {environment} from '../../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { SellerService } from 'app/core/seller/seller.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from 'app/core/user/user.service';


@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent {
  alltags$: Observable<any[]>;
  allsellers$: any[];
  selectedSeller: any;
  selectedTags: any[] = [];
  private _disabledV:string = '0';
  public disabled:boolean = false;
  isAdmin = false;
  
    menuItems = {
      title: '',
      description: '',
      thumb: '',
      sellerid: '',
      ntags: {},
      tags: '',
      isActive: false,    
      offer: {
        hasOffer: false,
        offerPercentage: 0
      },
      preptimehrs: 0,
      quantityrange: {min: 0, max: 10},
      price: {
        pname: '',
        value: 0,
        specialPrice: 0
      },
      additionalprice: [],
      extraOptions: [],
      category: {
        id: '',
        name: ''
      }, 
      locationsserved: '',
      seller: {
        name: '',
        thumb: '',
        storename: ''
      }
    };
    currencysymbol = Configs[environment.envname].Currency.symbol;
    menuItemsOld = {
         title: '',
         description: '',
         offerPercentage: 0,
         offer: false,
         extraOptions: [{}],
         price: [{}],
         quantityrange: {min: 0, max: 10},
         preptimehrs: 0,
         category: '',
         thumb: '',
         sellerId: '',
         ntags: {},
         tags: '',
         isActive: true
     };
    ItemPrice = 0;
    ItemAdditionalPrice = [];
    url: any = '';
    categories: any = [];
    maxtags = Configs[environment.envname].menuitems.maxtags || 1;
    maxlocations = Configs[environment.envname].menuitems.maxlocations || 1;

    menuItemsdata: AngularFireObject<any>;
    categoryData: AngularFireList<any>;

    uploadedimage: any = null;

    readUrl(event) {
      if (event.target.files && event.target.files[0]) {
        const menuimagefile = event.target.files[0];
        this.ng2ImgMax.resizeImage(menuimagefile, Configs[environment.envname].menuitems.imagesize.width, Configs[environment.envname].menuitems.imagesize.height).subscribe((imageResized: File) => {
          this.uploadedimage = imageResized;
          const reader = new FileReader();
          reader.onload = (event2: any) => {
            this.url = event2.target.result;
            this.menuItems.thumb = event2.target.result;
            console.log('Image resized.')
            // this.imageRef = 1;
          };
          reader.readAsDataURL(imageResized);
        }, (error) => {
          console.log('😢 Oh no. Image Resize Error!', error);
        });
      }      
    }

    addNewChoice = function() {
      this.menuItems.extraOptions = this.menuItems.extraOptions || [];
      const newItemNo = this.menuItems.extraOptions.length + 1;
      this.menuItems.extraOptions.push({});
    };

    removeChoice = function() {
      if (this.menuItems.extraOptions.length > 0) {
        const lastItem = this.menuItems.extraOptions.length - 1;
        this.menuItems.extraOptions.splice(lastItem);
      }
    };
    addNewaddtlPrice = function() {
      const newItemNo = this.menuItems.additionalprice.length + 1;
      this.menuItems.additionalprice.push({});
    };

    removeaddtlPrice = function() {
      if (this.menuItems.additionalprice.length > 0) {
          const lastItem = this.menuItems.additionalprice.length - 1;
          this.menuItems.additionalprice.splice(lastItem);
      } 
    };

    constructor(  private route: ActivatedRoute, 
                  public storage: AngularFireStorage, 
                  public afa: AngularFireAuth, 
                  public router: Router, 
                  public af: AngularFireDatabase, 
                  public toastr: ToastrService, 
                  public seller: SellerService, 
                  public userService: UserService,
                  private ng2ImgMax: Ng2ImgMaxService, 
                  private sellerservice: SellerService) {
      this.categoryData = this.af.list('/categories');
      this.alltags$ = this.sellerservice.getTags();
      
      this.categoryData.snapshotChanges().subscribe((categoryresponse: any[]) => {
          const _categories = [];
          categoryresponse.forEach((_o: any) => {
            const _category = _o.payload.toJSON();
            _category.$key = _o.key;
            _categories.push(_category);
          });
          this.categories = _categories;
      });
      
      this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
      if (Id != null) {
          console.log('Retrieving menu item');
          this.menuItemsdata = this.af.object('/menuItems/' + Id);
          this.menuItemsdata.valueChanges().subscribe(
            (response) => {
              this.menuItems.sellerid =  response.sellerid;
              this.menuItems.seller = {
                name: response.seller.name,
                thumb: response.seller.thumb,
                storename: response.seller.storename
              }
          
              if (response.category.id == null) {
                // old format.
                this.menuItems.title = response.title;
                this.menuItems.description = response.description;
                this.menuItems.thumb = response.thumb;
                this.menuItems.tags = response.tags || '';
                this.menuItems.ntags = response.ntags || {};
                this.menuItems.isActive = response.isActive || false;
                this.menuItems.preptimehrs = response.preptimehrs;
                this.menuItems.quantityrange = response.quantityrange;
                this.menuItems.additionalprice = response.additionalprice || [];
                this.menuItems.extraOptions = response.extraOptions || [];          
                this.menuItems.price = {
                  pname: response.price.pname || '',
                  value: response.price.value || 0,
                  specialPrice: response.price.specialPrice || 0
                }        
            
                let categoryfound = this.categories.find(x => x.$key == response.category);
                if ((categoryfound != null) && (categoryfound.title != null)){
                  this.menuItems.category = {
                    id: response.category,
                    name: categoryfound.title
                  };  
                } else {
                  this.menuItems.category = {
                    id: response.category,
                    name: 'unknown category'
                  };  
                }
                this.menuItems.offer.hasOffer = response.offer;
                this.menuItems.offer.offerPercentage = response.offerPercentage;
              } else {
                // allready new format
                this.menuItems = Object.assign({}, this.menuItems, response);
              }
              let _currentuser = this.userService.getCurrentUser();
              this.selectedTags = (this.menuItems.ntags == null) ? [] : this.GetTagsArray(this.menuItems.ntags);
              this.menuItems.locationsserved = '',
              this.sellerservice.getSellerById(this.menuItems.sellerid).then((sellerlatestinfo: any) => {
                this.menuItems.locationsserved = sellerlatestinfo.locationsserved || '';
              });
            }
          );
        }
      });
    }

    GetTagsArray(ntags: {}): any[] {
      let retarr = [];
        // tags.forEach((tag) => {
        for (const [key, value] of Object.entries(ntags)) {
          retarr.push({
            id: '',
            text: key
          });
        };
      return retarr;
    }


    onSubmitMainItems(form: NgForm) {
      this.menuItems.ntags = {};
      if ((this.selectedTags != null) && (this.selectedTags.length > 0)){
        this.selectedTags.forEach((x, i) => {
          this.menuItems.ntags[x.text] = 1;
        });
      }
      this.menuItems.tags = '';

      // Update seller info if changed.      
      /*if ((this.isAdmin) && (this.menuItems.sellerid !== this.selectedSeller.sellerId)) {
        this.menuItems.sellerid = this.selectedSeller.sellerId;
        this.menuItems.seller = {
          name: this.newselleruser.name,
          storename: this.selectedSeller.storeName,
          thumb: this.selectedSeller.profileimage
        }
      }*/

      if (this.menuItems.offer.offerPercentage > 0) {
          this.menuItems.price.specialPrice = this.menuItems.price.value - (this.menuItems.offer.offerPercentage * this.menuItems.price.value/100);
          this.ItemAdditionalPrice = this.menuItems.additionalprice;
          for (let i = 0; i < this.ItemAdditionalPrice.length; i++) {
            this.ItemAdditionalPrice[i].specialPrice = (this.ItemAdditionalPrice[i].value - (this.menuItems.offer.offerPercentage * this.ItemAdditionalPrice[i].value) / 100);
            console.log('this.ItemAdditionalPrice' + JSON.stringify(this.ItemAdditionalPrice));
          }
          console.log('else');
          this.menuItems.offer.hasOffer = true;
      } else {
        console.log('else');
        this.ItemPrice = this.menuItems.price.value;
        this.menuItems.offer.offerPercentage = 0;
        this.menuItems.price.specialPrice = 0;
        this.menuItems.offer.hasOffer = false;
      }
      /*let _seller = this.seller.getSeller();
      this.menuItems.sellerid =  _seller.uid;
      this.menuItems.seller = {
        name: _seller.name,
        thumb: _seller.business.profileimage,
        storename: _seller.business.storeName
      }*/
      if (this.uploadedimage != null) {
        // if the image is base 64.
        const filePath = Configs[environment.envname].menuitems.menuitemsimagesfolder + '/';
        const storageref = this.storage.ref(filePath);
        const filename = new Date().getTime() + '_' + this.uploadedimage.name;
        this.storage.upload(filePath + filename, this.uploadedimage)
          .then((uploaded) => {
            uploaded.ref.getDownloadURL().then((downloadurl) => {
              if (this.menuItems.thumb.startsWith('http')){
                // delete the old image.
                if (this.menuItems.thumb.indexOf("missingmenu.svg?") <= 0) {
                  this.storage.storage.refFromURL(this.menuItems.thumb).delete().then((result) => {
                    console.log('IMage ' + this.menuItems.thumb);
                  }, (err) => {
                    console.log('Error deleting old menuitem image... ' + err);
                  });  
                }
              }
              this.menuItems.thumb = downloadurl;
              this.menuItemsdata.update(this.menuItems).then((res2) => {
                this.toastr.success('Menu-Items Data Updated Successfully 1!', 'Success!');
                this.router.navigate(['/menu/manageItems']);
              });
            }, (err) => {
              console.log('error getting download url for menuitem: ' + err);
            });
          }, (err) => {
            console.log('error uploading image: ' + err);
          });
      } else {
        this.menuItemsdata.update(this.menuItems).then((res2) => {
          this.toastr.success('Menu-Items Data Updated Successfully 1!', 'Success!');
          this.router.navigate(['/menu/manageItems']);
        });
      }
    }

    cancel() {
      this.router.navigate(['/menu/manageItems']);
    }


    private get disabledV():string {
      return this._disabledV;
    }
    
    private set disabledV(value:string) {
      this._disabledV = value;
      this.disabled = this._disabledV === '1';
    }
    
    public selected(value:any):void {
      console.log('Selected value is: ', value);
    }
    
    public removed(value:any):void {
      console.log('Removed value is: ', value);
    }
    
    public typed(value:any):void {
      console.log('New search input: ', value);
    }
    
    public refreshValue(value:any):void {
     this.selectedTags = value.map(eachitem => {
       return eachitem.text;
     });
    }
}
