import {Component, NgZone} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {ToastrService} from 'ngx-toastr';
import {AngularFireAuth} from '@angular/fire/auth';
import {Configs} from 'app/config';
import {environment} from '../../../../environments/environment';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from 'app/core/user/user.service';
import { Observable } from 'rxjs';
import { SellerService } from '../../../../app/core/seller/seller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';



@Component({
    selector: 'app-business-info',
    templateUrl: './business-info.component.html',
    styleUrls: ['./business-info.component.scss']
})
export class BusinessInfoComponent {
    defaultgeo = {
        lat: 51.678418,
        lng: 7.809007,
        isDefaultLatLng: true,
        zoom: 10,
        radius: 5
    };

    isShortNameAvailable = 0;
    shortNameChanged = false;
    originalshortname = '';
    originalprofilepicture = '';
    allLocations$: Observable<string[]>;
    selectedLocation: any[] = [];
    private _disabledV:string = '0';
    public disabled:boolean = false;
    maxtags = Configs[environment.envname].menuitems.maxtags || 1;
    maxlocations = Configs[environment.envname].menuitems.maxlocations || 1;
    
    business_delivery_sellerdelivery: any = false;
    business = {
        $key: null,
        email: '',
        description: '',
        address: '',
        facebookLink: '',
        twitterLink: '',
        officeLocation: '',
        phoneNo: '',
        storeName: '',
        shortName: '',
        sellerId: '',
        workinghours: {
            starttime: {
                hour: 8,
                minute: 0
            },
            endtime: {
                hour: 20,
                minute: 0
            }
        },
        delivery: {
            seller: false,
            defaultcharge: 0,
            tiers: [
                {low: 0, high: 0, charge: 0},
                {low: 0, high: 0, charge: 0},
                {low: 0, high: 0, charge: 0},
                {low: 0, high: 0, charge: 0},
                {low: 0, high: 0, charge: 0}
            ]
        },
        profileimage: Configs[environment.envname].defaultprofileimage,
        locationsserved: '',
        razorpay: {
            account_id: '',
            status: '',
            jsonresult: ''
        },
        geo: this.defaultgeo
    };
    businessData: AngularFireList<any>;

    constructor(public af: AngularFireDatabase,
                public afa: AngularFireAuth,
                public storage: AngularFireStorage,
                private route: ActivatedRoute,
                public toastr: ToastrService,
                public router: Router, 
                private __zone: NgZone,
                private ng2ImgMax: Ng2ImgMaxService,
                private sellerservice: SellerService,
                private userservice: UserService) {
        this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
            console.log('View Business Info for ' + Id);
            let business_user: any = null;
            if (Id == null) {
                let _user = this.userservice.getCurrentUser(); 
                Id = _user.uid;
            }
           
            /*this.userservice.getUserById(Id).then((currentuser) => {
                console.log('Current User: ' + JSON.stringify(currentuser));
            });*/
            
            this.allLocations$ = this.sellerservice.getLocations();
            this.businessData = af.list('/business', ref => ref.orderByChild('sellerId').equalTo(Id).limitToFirst(1));
            this.business.sellerId = Id;
            this.businessData.snapshotChanges().subscribe((businessdataresponse: any[]) => {
                const _businessdatas = [];
                businessdataresponse.forEach((_o: any) => {
                    const _businessdata = _o.payload.toJSON();
                    _businessdata.$key = _o.key;
                    _businessdatas.push(_businessdata);
                })
    
                this.business = _businessdatas[0] || this.business;
                if (this.business.razorpay == null) {
                    this.business.razorpay = {
                        account_id: '',
                        status: '',
                        jsonresult: ''
                    }
                }
                
                this.business.geo = this.defaultgeo;
                this.business.shortName = this.business.shortName || '';
                this.business.workinghours = this.business.workinghours ||
                            {
                                starttime: {
                                    hour: 8,
                                    minute: 0
                                },
                                endtime: {
                                    hour: 20,
                                    minute: 0
                                }
                            };
                this.business.delivery = this.business.delivery || {seller: true,
                    defaultcharge: 0,
                    tiers: [
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0}
                    ]};
                this.business.delivery.defaultcharge = this.business.delivery.defaultcharge || 0;
                this.business.delivery.tiers = this.business.delivery.tiers || [
                    {low: 0, high: 0, charge: 0},
                    {low: 0, high: 0, charge: 0},
                    {low: 0, high: 0, charge: 0},
                    {low: 0, high: 0, charge: 0},
                    {low: 0, high: 0, charge: 0}
                ];
                this.originalshortname = this.business.shortName;
                this.originalprofilepicture = this.business.profileimage;
                this.selectedLocation = (this.business.locationsserved == null ? [] : this.business.locationsserved.split(',')).map((str, index) => ({text: str, id: index+1}));
            });
        });
    }

    private getUserLocation() {
        /// locate the user
        if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(position => {
            this.defaultgeo.lat = position.coords.latitude;
            this.defaultgeo.lng = position.coords.longitude;
          });
        }
    }

    uploadedimage: any = null;
    url: any = '';

    readUrl(event) {
        if (event.target.files && event.target.files[0]) {
            const profileimagefile = event.target.files[0];
            this.ng2ImgMax.resizeImage(profileimagefile, Configs[environment.envname].userprofile.imagesize.width, Configs[environment.envname].userprofile.imagesize.height).subscribe((imageResized: File) => {
                this.uploadedimage = imageResized;
                const reader = new FileReader();
                reader.onload = (event2: any) => {
                    this.url = event2.target.result;
                    this.business.profileimage = event2.target.result;
                     console.log('Image resized.')
                }
                reader.readAsDataURL(event.target.files[0]);
            }, (error) => {
                console.log('😢 Oh no. Image Resize Error!', error);
            });
        }
    }

    radiusChanged(newradius: any) {
        this.business.geo.radius = newradius / 1000;
    }

    markerDragEnd($event: any) {
        console.log('dragEnd', $event);
        this.business.geo.lat = $event.coords.lat;
        this.business.geo.lng = $event.coords.lng;
        this.business.geo.isDefaultLatLng = false;
    }

    onAddressUpdate($event) {
        /*this.gMapsService.getLatLong(this.business.address)
            .subscribe(
                result => {
                    this.__zone.run(() => {
                        this.business.geo.lat = result.lat();
                        this.business.geo.lng = result.lng();
                        this.business.geo.isDefaultLatLng = false;
                    });
                },
                error => console.log(error),
                () => console.log('Geocoding completed!')
            );*/
    }

    checkShortNameAvailability() {
        this.sellerservice.checkShortNameAvailability(this.business.sellerId, this.business.shortName)
            .then(() => {
                console.log('Shortname is available');
                this.isShortNameAvailable = 1;
            }, () => {
                this.isShortNameAvailable = -1;
            });
    }

    saveProfile() {
        // let currentUser = this.userservice.getCurrentUser();
        let _selectedlocations = this.selectedLocation.map(eachitem => eachitem.text).join(',') || '';
        if (this.business.$key === null) {
            this.businessData.push({
                email: this.business.email,
                description: this.business.description,
                address: this.business.address,
                workinghours: this.business.workinghours,
                delivery: this.business.delivery || {seller: true,
                    defaultcharge: 0,
                    tiers: [
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0},
                        {low: 0, high: 0, charge: 0}
                    ]},
                facebookLink: this.business.facebookLink,
                twitterLink: this.business.twitterLink,
                officeLocation: this.business.officeLocation,
                phoneNo: this.business.phoneNo,
                storeName: this.business.storeName,
                shortName: this.business.shortName,
                sellerId: this.business.sellerId,
                profileimage: this.business.profileimage,
                deliveryradius: this.business.geo.radius,
                locationsserved: _selectedlocations
            }).then((res) => {
                this.toastr.success('Your Business details added!', 'Success!');
            });
        } else {
            this.af.object('/business/' + this.business.$key)
                .update({
                    email: this.business.email,
                    description: this.business.description,
                    address: this.business.address,        
                    workinghours: this.business.workinghours,            
                    delivery: this.business.delivery || {seller: true,
                        defaultcharge: 0,
                        tiers: [
                            {low: 0, high: 0, charge: 0},
                            {low: 0, high: 0, charge: 0},
                            {low: 0, high: 0, charge: 0},
                            {low: 0, high: 0, charge: 0},
                            {low: 0, high: 0, charge: 0}
                        ]},
                    facebookLink: this.business.facebookLink,
                    twitterLink: this.business.twitterLink,
                    officeLocation: this.business.officeLocation,
                    phoneNo: this.business.phoneNo,
                    storeName: this.business.storeName,
                    shortName: this.business.shortName,
                    sellerId: this.business.sellerId,
                    profileimage: this.business.profileimage,
                    deliveryradius: this.business.geo.radius,
                    locationsserved: _selectedlocations
                })
                .then((res) => {
                    this.toastr.success('Your Business details updated!', 'Success!');
                });
        }
        if (this.shortNameChanged) {
            this.sellerservice.saveShortName({sellerId: this.business.sellerId, storeName: this.business.storeName}, this.business.shortName, this.originalshortname);
        }
    }

    onSubmitBusiness(form: NgForm) {

        if (this.originalprofilepicture !== this.business.profileimage) {
            // Image was uploaded.
            this.business.profileimage = ''; // we are not saving base64;
            const filePath = Configs[environment.envname].userprofile.profileimagesfolder + '/';
            const storageref = this.storage.ref(filePath);
      
            if (this.uploadedimage == null) {
                // image not specified.. we will use the default image then.
                const filename = Configs[environment.envname].userprofile.missingprofileitem;
                this.storage.ref(filePath + filename).getDownloadURL().toPromise().then((downloadurl) => {
                    this.business.profileimage = downloadurl;
                    this.saveProfile();
                }, (err) => {
                    console.log('error getting download url for userprofile: ' + err);
                });
            } else {
                const filename = new Date().getTime() + '_' + this.uploadedimage.name;
                this.storage.upload(filePath + filename, this.uploadedimage, {cacheControl: 'public, max-age=31536000'})
                .then((uploaded) => {
                    uploaded.ref.getDownloadURL().then((downloadurl) => {
                        this.business.profileimage = downloadurl;
                        this.saveProfile();
                    });
                });
            } 
        } else {
            // Profile image was not changed. So dont need to reupload to storage
            this.saveProfile();
            console.log('saved');
        }
    }


    private get disabledV():string {
        return this._disabledV;
    }
    
    private set disabledV(value:string) {
        this._disabledV = value;
        this.disabled = this._disabledV === '1';
    }
    
    public selected(value:any):void {
        console.log('Selected value is: ', value);
    }
    
    public removed(value:any):void {
        console.log('Removed value is: ', value);
    }
    
    public typed(value:any):void {
        console.log('New search input: ', value);
    }
    
    public refreshValue(value:any):void {
        this.selectedLocation = value.map(eachitem => {
            return eachitem.text;
        });
    }
}

