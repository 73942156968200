
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};


const Menu = {
    text: 'Menu Items',
    link: '/menu/manageItems',
    icon: 'fa fa-th-list'
};

const Orders = {
    text : 'Orders',
    link: '/order/allOrder',
    icon: 'fa fa-bars'
};


const Business = {
    text: 'Business Info',
    link: '/businessinfo/my',
    icon: 'fa fa-briefcase'
};


const logoutUser = {
    text: 'Logout',
    link: '/logout',
    icon: 'fa fa-sign-out',
    click: 'logout'
};

// const Pages = {
//     text: 'Pages',
//     link: '/pages',
//     icon: 'icon-doc',
//     submenu: [
//         {
//             text: 'Login',
//             link: '/login'
//         },
//         {
//             text: 'Register',
//             link: '/register'
//         },
//         {
//             text: 'Recover',
//             link: '/recover'
//         },
//         {
//             text: '404',
//             link: '/404'
//         }
//     ]
// };

// const Ecommerce = {
//     text: 'Ecommerce',
//     link: '/ecommerce',
//     icon: 'icon-basket-loaded',
//     submenu: [
//         {
//             text: 'Orders',
//             lin k: '/ecommerce/orders'
//         },
//         {
//             text: 'Order View',
//             link: '/ecommerce/orderview'
//         },
//         {
//             text: 'Products',
//             link: '/ecommerce/products'
//         },
//         {
//             text: 'Product View',
//             link: '/ecommerce/productview'
//         },
//         {
//             text: 'Checkout',
//             link: '/ecommerce/checkout'
//         }
//     ]
// }




const headingMain = {
    text: 'Navigation',
    heading: true
};

const headingComponents = {
    text: 'Components',
    heading: true
};

const headingMore = {
    text: 'More',
    heading: true
};

const Categories = {
    text: 'Categories',
    link: '/categories/manageCategories',
    icon: 'icon-note'
};
const Users = {
    text: 'Users',
    link: '/users/manageUsers',
    icon: 'fa fa-users'
};
const Chefs = {
    text: 'Chefs',
    link: '/chefs/manageChefs',
    icon: 'fa fa-users'
};
const Setting = {
    text: 'Setting',
    link: '/setting',
    icon: 'fa fa-cog'
};

const Tags = {
    text: 'Tags',
    link: '/tags/all',
    icon: 'fa fa-tags'
};

const Locations = {
    text: 'Locations',
    link: '/locations/all',
    icon: 'fa fa-map-marker'
};

const News = {
    text: 'News',
    link: '/news/manageNews',
    icon: 'fa fa-newspaper-o'
};
const Coupons = {
    text: 'Coupons',
    link: '/coupons/all',
    icon: 'fa fa-barcode'
};

const pushNotification = {
    text: 'Push Notification',
    link: '/pushNotification',
    icon: 'fa fa-briefcase'
};

export const menu = [
    Home,
    Business,
    Menu,
    Orders,
    logoutUser
];

export const adminmenu = [
    // Categories, 
    Chefs,
    Users, 
    Setting, 
    Tags, 
    Locations,
    News, 
    Coupons, 
    //pushNotification
];
