<!-- START Sidebar (left)-->
<div class="aside-inner">
    <nav class="sidebar" sidebar-anyclick-close="" [class.show-scrollbar]="settings.layout.asideScrollbar">

        <!-- START sidebar nav-->
        <ul class="nav">

            <!-- START user info-->
            <!-- <li class="has-user-block">
                <app-userblock></app-userblock>
            </li> -->
            <!-- END user info-->

            <li *ngFor='let item of menuItems' [ngClass]="{'nav-heading': item.heading}" [routerLinkActive]="['active']">
                <!-- menu heading -->
                <span *ngIf="item.heading">{{(item.translate | translate) || item.text}}</span>
                <!-- single menu item -->
                <a *ngIf="!item.heading && !item.submenu" [routerLink]="item.link" [attr.route]="item.link" title="{{item.text}}" [attr.menuclick]="item.click"
                    (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
                    <span class="pull-right" *ngIf="item.alert" [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
                    <em class="{{item.icon}}" *ngIf="item.icon"></em>
                    <span>{{(item.translate | translate) || item.text}}</span>
                </a>
                <!-- has submenu -->
                <a *ngIf="!item.heading && item.submenu" title="{{item.text}}"
                    (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
                        <span class="pull-right" *ngIf="item.alert" [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
                        <em class="{{item.icon}}" *ngIf="item.icon"></em>
                        <span>{{(item.translate | translate) || item.text}}</span>
                </a>
                <!-- SUBLEVEL -->
                <ul *ngIf="item.submenu" class="nav sidebar-subnav" [routerLinkActive]="['opening']">
                    <li class="sidebar-subnav-header">{{(item.translate | translate) || item.text}}</li>
                    <li *ngFor='let subitem of item.submenu' [routerLinkActive]="['active']">
                        <!-- sublevel: single menu item  -->
                        <a *ngIf="!subitem.submenu" [routerLink]="subitem.link" [attr.route]="subitem.link" title="{{subitem.text}}">
                            <span class="pull-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                            <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                            <span>{{(subitem.translate | translate) || subitem.text}}</span>
                        </a>
                        <!-- sublevel: has submenu -->
                        <a *ngIf="subitem.submenu" title="{{subitem.text}}"
                            (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
                                <span class="pull-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                                <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                                <span>{{(subitem.translate | translate) || subitem.text}}</span>
                        </a>
                        <!-- SUBLEVEL 2 -->
                        <ul *ngIf="subitem.submenu" class="nav sidebar-subnav level2" [routerLinkActive]="['opening']">
                            <li *ngFor='let subitem2 of subitem.submenu' [routerLinkActive]="['active']">
                                <!-- sublevel 2: single menu item  -->
                                <a *ngIf="!subitem2.submenu" [routerLink]="subitem2.link" [attr.route]="subitem2.link" title="{{subitem2.text}}">
                                    <span class="pull-right" *ngIf="subitem2.alert" [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                                    <em class="{{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                                    <span>{{(subitem2.translate | translate) || subitem2.text}}</span>
                                </a>
                                <!-- sublevel2: has submenu -->
                                <a *ngIf="subitem2.submenu" title="{{subitem2.text}}"
                                    (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
                                        <span class="pull-right" *ngIf="subitem2.alert" [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                                        <em class="{{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                                        <span>{{(subitem2.translate | translate) || subitem2.text}}</span>
                                </a>
                                <!-- SUBLEVEL 3 -->
                                <ul *ngIf="subitem2.submenu" class="nav sidebar-subnav level3" [routerLinkActive]="['opening']">
                                    <li *ngFor='let subitem3 of subitem2.submenu' [routerLinkActive]="['active']">
                                        <!-- sublevel 2: single menu item  -->
                                        <a *ngIf="!subitem3.submenu" [routerLink]="subitem3.link" [attr.route]="subitem3.link" title="{{subitem3.text}}">
                                            <span class="pull-right" *ngIf="subitem3.alert" [ngClass]="subitem3.label || 'label label-success'">{{subitem3.alert}}</span>
                                            <em class="{{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                                            <span>{{(subitem3.translate | translate) || subitem3.text}}</span>
                                        </a>
                                        <!-- sublevel3: has submenu -->
                                        <a *ngIf="subitem3.submenu" title="{{subitem3.text}}"
                                            (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
                                                <span class="pull-right" *ngIf="subitem3.alert" [ngClass]="subitem3.label || 'label label-success'">{{subitem3.alert}}</span>
                                                <em class="{{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                                                <span>{{(subitem3.translate | translate) || subitem3.text}}</span>
                                        </a>
                                        <!-- SUBLEVEL 4 -->
                                        <ul *ngIf="subitem3.submenu" class="nav sidebar-subnav level3" [routerLinkActive]="['opening']">
                                            <li *ngFor='let subitem4 of subitem3.submenu' [routerLinkActive]="['active']">
                                                <!-- sublevel 2: single menu item  -->
                                                <a *ngIf="!subitem4.submenu" [routerLink]="subitem4.link" [attr.route]="subitem4.link" title="{{subitem4.text}}">
                                                    <span class="pull-right" *ngIf="subitem4.alert" [ngClass]="subitem4.label || 'label label-success'">{{subitem4.alert}}</span>
                                                    <em class="{{subitem4.icon}}" *ngIf="subitem4.icon"></em>
                                                    <span>{{(subitem4.translate | translate) || subitem4.text}}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            <li>
                        </ul>
                    <li>
                </ul>
            </li>

        </ul>
        <!-- END sidebar nav-->
        <div>v{{version}}</div>
        <div>CDN: {{cdnserver}}</div>
    </nav>
</div>
<!-- END Sidebar (left)-->
