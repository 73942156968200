import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'app/core/user/user.service';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {
  isAdmin = false;
  orders: Array<any>;
  ordersData: AngularFireList<any>;
  constructor(public af: AngularFireDatabase, public afa: AngularFireAuth, public toastr: ToastrService, public router: Router, public userService: UserService) {

    let currentuser = this.userService.getCurrentUser();
    this.isAdmin = this.userService.isAdmin();
    if (currentuser.role == 'admin') {
      this.ordersData = af.list('/suborders', ref => ref.orderByChild('sellerid')); // Get all menu items.
    } else {
      this.ordersData = af.list('/suborders', ref => ref.orderByChild('sellerId').equalTo(currentuser.uid));
    }
    

    this.ordersData.snapshotChanges().subscribe((res: any[]) => {
      const _ordersdata = [];
      res.forEach((_o: any) => {
        const _order = _o.payload.toJSON();
        _order.$key = _o.key;
        _ordersdata.push(_order);
      });

      this.orders = _ordersdata;
    });
  }

  OnChangeStatus(key, event) {
    this.ordersData.update(key, {status: event.target.value}).then((res) => {
          this.toastr.success('Order status updated!', 'Success!');
      });
  }
  ordersShow(key) {
    console.log(key);
    this.router.navigate(['/order/viewOrder', key]);
  }
}
