<div class="content-heading">View Chef
<small>
        <a [routerLink]="['/chefs/manageChef']"> Manage Chef </a>->
            View Chef 
 </small>
 </div>
<div class="col-masonry">
        <div class="panel b m0">
            <div class="panel-heading">
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-3 col-sm-12 inlineblock" >
                        <h4 class="media-heading">Name</h4>
                    </div>
                    <div class="col-9 col-sm-12 inlineblock">
                        <p>{{userDetails.name}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 col-sm-12 inlineblock">
                        <h4 class="media-heading">Email</h4>
                    </div>
                    <div class="col-9 col-sm-12 inlineblock">
                        <p>{{userDetails.email}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 col-sm-12 inlineblock">
                        <h4 class="media-heading">Mobile Number</h4>
                    </div>
                    <div class="col-9 col-sm-12 inlineblock">
                        <p>{{userDetails.mobileNo}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 col-sm-12 inlineblock">
                        <h4 class="media-heading">Role</h4>
                    </div>
                    <div class="col-9 col-sm-12 inlineblock">
                        <p>{{userDetails.role}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 col-sm-12 inlineblock">
                        <h4 class="media-heading">Featured Chef</h4>
                    </div>
                    <div class="col-9 col-sm-12 inlineblock">
                        <span *ngIf="userDetails.isFeaturedChef">Yes</span>
                        <button class="btn btn-sm btn-default" style="margin-left: 10px;" *ngIf="userDetails.isFeaturedChef" type="button" (click)="setFeaturedChef(userDetails, false)">
                            <em class="fa fa-eye"></em>
                            Remove Feature Chef
                        </button>
                        <span *ngIf="!userDetails.isFeaturedChef">No</span>
                        <button class="btn btn-sm btn-default" style="margin-left: 10px;" *ngIf="!userDetails.isFeaturedChef" type="button" (click)="setFeaturedChef(userDetails, true)">
                            <em class="fa fa-eye"></em>
                            Make Feature Chef
                        </button>
                    </div>
                </div>

            </div>
            <div class="panel-body">
                <button class="btn btn-sm btn-default" type="button" (click)="viewBusinessInfo()">
                    <em class="fa fa-eye"></em>
                    View Business Info
                </button>
            </div>
        </div>
    </div>
