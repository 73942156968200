<div class="content-heading"> Business User
    <small style="color: black; margin-top: 5px;">
        <a [routerLink]=" ['/home'] " >Dashboard</a> -> Business User
    </small>
    <div class="pull-right" style="top: -40px; position: relative;">
        <label for="image-input" class="file-upload">
          <a [routerLink]="'/businessinfo/update-business-bank-details/' + business.sellerId">  <button class="btn btn-success"><em class="fa fa-plus"></em>&nbsp;&nbsp;Update Bank Details</button> </a>	
        </label>
</div>
</div>
<!-- START panel-->
<div class="panel panel-default">
    <div class="panel-heading">Business Details</div>
    <div class="panel-body">
        <form role="form" (ngSubmit)="onSubmitBusiness(f)" #f="ngForm">
            <fieldset>
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Store Name</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="storeName" id="storeName"
                                    [(ngModel)]="business.storeName" type="text"
                                    placeholder="Enter Store Name"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Phone Number</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="phoneNo" id="phoneNo" [(ngModel)]="business.phoneNo"
                                    type="number" placeholder="Enter Phone Number"/>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Photo</label>
                    <div class="col-sm-10">
                        <div class="panel">                                                      
                            <div class="panel-body mh0" [hidden]="business.profileimage ==''" (click)="profileupload.click()">
                                <img class="media-object img-thumbnail thumb96" [src]="business.profileimage" />
                                <input type="file" #profileupload style="display:none;" class="upload" accept="image/*" (change)="readUrl($event)">
                            </div><!--COL-->
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Locations Served
                    </label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <div style="width: 300px; margin-bottom: 20px;">
                                    <ng-select
                                        [items]="allLocations$ | async"
                                        name="locations"
                                        [multiple]="true"
                                        [closeOnSelect]="true"
                                        [searchable]="true"
                                        bindLabel="text"
                                        maxSelectedItems="{{maxlocations}}"
                                        placeholder="Select Locations"
                                        [(ngModel)]="selectedLocation">
                                    </ng-select>
                                  </div>                 
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Working Hours
                    </label>

                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <div style="width: 300px; margin-bottom: 20px;">
                                    <ngb-timepicker ngDefaultControl name="starttime" id="starttime" [(ngModel)]="business.workinghours.starttime"></ngb-timepicker>
                                    <ngb-timepicker ngDefaultControl name="endtime" id="endtime" [(ngModel)]="business.workinghours.endtime"></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row panel">
                    <label class="col-sm-2 control-label">Delivery By</label>
                    <div class="col-sm-10">
                        <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="inlineRadio1"
                              name="business.delivery.seller"
                              [(ngModel)]="business.delivery.seller"
                              [value]="true"
                            />
                            <label class="form-check-label" style="margin-left: 5px;" for="inlineRadio1">
                              Seller Delivers
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="inlineRadio2"
                              name="business.delivery.seller"
                              [(ngModel)]="business.delivery.seller"
                              [value]="false"
                            />
                            <label class="form-check-label" style="margin-left: 5px;" for="inlineRadio2">
                              Vegecious Delivers
                            </label>
                          </div>
                    </div>               
                </div>

                <div class="row panel" *ngIf="business.delivery.seller">
                    <label class="col-sm-2">Default Delivery Charge</label>
                    <div class="col-sm-10">
                        <input type="number" placeholder="default delivery charge" name="business.delivery.defaultcharge" id="business.delivery.defaultcharge" [(ngModel)]="business.delivery.defaultcharge"/>
                    </div>
                </div>

                <div class="row panel" *ngIf="business.delivery.seller">
                    <label class="col-sm-2">Delivery Charge Tier</label>
                    <div class="col-sm-10">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th scope="col">Tier #</th>
                                    <th scope="col">Low</th>
                                    <th scope="col">High</th>
                                    <th scope="col">Charge</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td><input type="number" placeholder="tier1 low" name="tier1_low" id="tier1_low" [(ngModel)]="business.delivery.tiers[0].low"/></td>
                                        <td><input type="number" placeholder="tier1 high" name="tier1_high" id="tier1_high" [(ngModel)]="business.delivery.tiers[0].high"/></td>
                                        <td><input type="number" placeholder="tier1 charge" name="tier1_chrg" id="tier1_chrg" [(ngModel)]="business.delivery.tiers[0].charge"/></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td><input type="number" placeholder="tier2 low" name="tier2_low" id="tier2_low" [(ngModel)]="business.delivery.tiers[1].low"/></td>
                                        <td><input type="number" placeholder="tier2 high" name="tier2_high" id="tier2_high" [(ngModel)]="business.delivery.tiers[1].high"/></td>
                                        <td><input type="number" placeholder="tier2 charge" name="tier2_chrg" id="tier2_chrg" [(ngModel)]="business.delivery.tiers[1].charge"/></td>
                                    </tr>
                                    <tr style="display: none;">
                                        <th scope="row">3</th>
                                        <td><input type="number" placeholder="tier3 low" name="tier3_low" id="tier3_low" [(ngModel)]="business.delivery.tiers[2].low"/></td>
                                        <td><input type="number" placeholder="tier3 high" name="tier3_high" id="tier3_high" [(ngModel)]="business.delivery.tiers[2].high"/></td>
                                        <td><input type="number" placeholder="tier3 charge" name="tier3_chrg" id="tier3_chrg" [(ngModel)]="business.delivery.tiers[2].charge"/></td>
                                    </tr>
                                    <tr style="display: none;">
                                        <th scope="row">4</th>
                                        <td><input type="number" placeholder="tier4 low" name="tier4_low" id="tier4_low" [(ngModel)]="business.delivery.tiers[3].low"/></td>
                                        <td><input type="number" placeholder="tier4 high" name="tier4_high" id="tier4_high" [(ngModel)]="business.delivery.tiers[3].high"/></td>
                                        <td><input type="number" placeholder="tier4 charge" name="tier4_chrg" id="tier4_chrg" [(ngModel)]="business.delivery.tiers[3].charge"/></td>
                                    </tr>
                                    <tr style="display: none;">
                                        <th scope="row">5</th>
                                        <td><input type="number" placeholder="tier5 low" name="tier5_low" id="tier5_low" [(ngModel)]="business.delivery.tiers[4].low"/></td>
                                        <td><input type="number" placeholder="tier5 high" name="tier5_high" id="tier5_high" [(ngModel)]="business.delivery.tiers[4].high"/></td>
                                        <td><input type="number" placeholder="tier5 charge" name="tier5_chrg" id="tier5_chrg" [(ngModel)]="business.    delivery.tiers[4].charge"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Email</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="email" id="email" [(ngModel)]="business.email"
                                    type="Email" placeholder="Enter Email..."/>
                            </div>
                        </div>
                    </div>               
                </div>
                
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Description</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <textarea class="form-control" name="description" id="description"
                                        [(ngModel)]="business.description" required="" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Address</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                    <input class="form-control" name="address" id="address" [(ngModel)]="business.address"
                                    type="text" placeholder="Enter address..." required=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <!--
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Geo CoOrdinates</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                    <agm-map [latitude]="business.geo.lat" [longitude]="business.geo.lng" [zoom]="business.geo.zoom">
                                        <agm-marker [latitude]="business.geo.lat" [longitude]="business.geo.lng" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
                                            <agm-info-window>
                                                <h3><strong>{{business.storeName}}</strong></h3>
                                                <p>You are here!</p>
                                              </agm-info-window>                                        
                                        </agm-marker>
                                        <agm-circle [latitude]="business.geo.lat" 
                                                    [longitude]="business.geo.lng" 
                                                    [radius]="business.geo.radius*1000"
                                                    [fillColor]="'red'"
                                                    [circleDraggable]="true"
                                                    [editable]="true"
                                                    (radiusChange)="radiusChanged($event)">
                                        </agm-circle>
                                    </agm-map>
                                    <div>Latitude: {{business.geo.lat}}</div>
                                    <div>Longitude: {{business.geo.lng}}</div>
                                    <div>Radius: {{business.geo.radius}} Kms</div>
                            </div>
                        </div>
                    </div>
                </div>
                -->
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Facebook Page Link</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="facebookLink" id="facebookLink"
                                    [(ngModel)]="business.facebookLink" type="text"
                                    placeholder="Enter Facebook Link"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Twitter Page Link</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="twitterLink" id="twitterLink"
                                    [(ngModel)]="business.twitterLink" type="text"
                                    placeholder="Enter Twitter Link"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Chef Page Short Name (for chef personal page url)</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" style="display: inline-block;width:50%; margin-right: 20px;" name="shortName" id="shortName"
                                    [(ngModel)]="business.shortName" type="text"
                                    placeholder="Enter Short Name" (change)="shortNameChanged = true"/>
                                <a class="btn btn-primary" (click)="checkShortNameAvailability()">Check Availability <i *ngIf="isShortNameAvailable == 1;" class="fa fa-check-circle"></i><i *ngIf="isShortNameAvailable == -1;"class="fa fa-times-circle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--
                <div class="row panel">
                    <label class="col-sm-2 control-label custom">Office Location</label>
                    <div class="col-sm-10">
                        <div class="panel">
                            <div class="panel-body mh0">
                                <input class="form-control" name="officeLocation" id="officeLocation"
                                    [(ngModel)]="business.officeLocation" type="text"
                                    placeholder="Enter Office Location"/>
                            </div>
                        </div>
                    </div>
                </div>               
                -->
                
                <div class="row panel">
                    <div class="col-md-12">
                        <button class="btn btn-primary pull-right" type="submit">Submit</button>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>
