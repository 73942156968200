import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { SellerService } from 'app/core/seller/seller.service';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-add-coupons',
    templateUrl: './add-coupons.component.html',
    styleUrls: ['./add-coupons.component.scss']
})
export class AddCouponsComponent {

    coupon: any = {
        promocode: '',
        dsctpct: 0,
        dsctamt: 0,
        amountlimit: 0,
        amountlimitpertx: 0,
        maxusecount: 0,
        validdates: {
            fromDate: NgbDate,
            toDate: NgbDate
        }
    };
    couponData: AngularFireList<any>;

    hoveredDate: NgbDate | null = null;
    allpromorules: string[];
    selectedPromoRules: string[] = [];
    selectedSellers: any[] = [];
    
    allpromodiscountlogics: string[];
    selecteddiscountlogic: string[] = [];

    allsellers: any[] = [];


    constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService, public sellerservice: SellerService, calendar: NgbCalendar) {
        console.log('Add coupon');
        this.couponData = this.af.list('/coupons');
        this.coupon.validdates.fromDate = calendar.getToday();
        this.coupon.validdates.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
        this.allpromorules = this.sellerservice.getPromoRules();
        this.sellerservice.getAllSellers().then((_allsellers) => {
          console.log('all sellers');
          this.allsellers = _allsellers.map((x) => {return {name: x.storeName, sellerid: x.sellerId}});
        });
        this.allpromodiscountlogics = this.sellerservice.getPromoDiscountLogics();
    }

    onSubmitPromo(form: NgForm) {
        const dtnow = new Date();
        this.coupon.createdateinUTC = dtnow.getUTCDate();
        this.coupon.rules = this.selectedPromoRules.join(',');
        this.coupon.sellers = this.selectedSellers;
        this.coupon.applydiscount = this.selecteddiscountlogic;
        this.coupon.ordersappliedon = [-1];
        this.coupon.timesused = 0;
        this.coupon.amountused = 0;
        
        this.couponData.push(this.coupon).then((res) => {
            this.router.navigate(['/coupons/all']);
            this.toastr.success('Coupon Added Successfully!', 'Success!');
        });
    }
     cancel() {
       this.router.navigate(['/coupons/all']);
    }


    onDateSelection(date: NgbDate) {
        if (!this.coupon.validdates.fromDate && !this.coupon.validdates.toDate) {
          this.coupon.validdates.fromDate = date;
        } else if (this.coupon.validdates.fromDate && !this.coupon.validdates.toDate && date.after(this.coupon.validdates.fromDate)) {
          this.coupon.validdates.toDate = date;
        } else {
          this.coupon.validdates.toDate = null;
          this.coupon.validdates.fromDate = date;
        }
      }
    
      isHovered(date: NgbDate) {
        return this.coupon.validdates.fromDate && !this.coupon.validdates.toDate && this.hoveredDate && date.after(this.coupon.validdates.fromDate) && date.before(this.hoveredDate);
      }
    
      isInside(date: NgbDate) {
        return this.coupon.validdates.toDate && date.after(this.coupon.validdates.fromDate) && date.before(this.coupon.validdates.toDate);
      }
    
      isRange(date: NgbDate) {
        return date.equals(this.coupon.validdates.fromDate) || (this.coupon.validdates.toDate && date.equals(this.coupon.validdates.toDate)) || this.isInside(date) || this.isHovered(date);
      }
}

