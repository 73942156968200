import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';

@Pipe({
    name: 'firebaseimageurl'
})
export class FirebaseimageurlPipe implements PipeTransform {
    fbstorageref: AngularFireStorageReference = null;
    constructor(private storage: AngularFireStorage) {
        //this.fbstorageref = firebase.storage().ref();
        this.fbstorageref = this.storage.ref('');
    }

    transform(value: string): Promise<any> {
        return this.fbstorageref.child(value).getDownloadURL()
            .then(function(url) {
                return url;
            })
            .catch(function(err) {
                return 'error.jpg';
            });
    }
}
