import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent {

  locations: Array<any>;
  locationsData: AngularFireList<any>;
  constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService) {
    this.locationsData = af.list('/locations');
    this.locationsData.snapshotChanges().subscribe((res: any[]) => {
      const _locationsdata =[];
      res.forEach((_t: any) => {
        const _location = _t.payload.toJSON();
        _location.$key = _t.key;
        _locationsdata.push(_location);
      })
      this.locations = _locationsdata;
      console.log(this.locations);
    });
  }

  locationEdit(key) {
    this.router.navigate(['/locations/editLocations', key]);
  }

  locationDelete(key: any) {
    Swal.fire({
            title: 'Are you sure?',
            text: 'Your will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            preConfirm: function() {
              return new Promise<void>(function(resolve) {
                setTimeout(function() {
                  resolve()
                }, 2000)
              })
            }
      }).then((result: any) => {
        if (result.value) {
          this.locationsData.remove(key).then((res) => {
              Swal.fire('Deleted!', 'Location Deleted Successfully!', 'success');
            });
         } else if (result.dismiss === Swal.DismissReason.cancel) {
           Swal.fire('Cancelled', 'Your data is safe :)', 'error');
        }
      });
  }
}
