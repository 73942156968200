<div class="content-heading ">
  Push Notification
</div>
<div class="container-md">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-9">
      <div class="panel b">
        <div class="panel-heading  text-bold bg-purple-dark">Send Notification</div>
        <div class="panel-body">
          <form (ngSubmit)="onpushNotification(f)" #f="ngForm">
            <div class="form-group">
              <label>Title*</label>
              <input class="form-control" type="text" required name="title" id="title"
                     [(ngModel)]="message.headings.en"/>
            </div>
            <div class="form-group">
              <label>Message*</label>
              <textarea class="form-control" rows="3" name="title" id="title"
                        [(ngModel)]="message.contents.en" required></textarea>
            </div>
            <button class="btn btn-danger pull-right s-n-btn" (click)="cancel()">Cancel</button>
            <button class="btn btn-primary pull-right s-n-btn" type="submit">Send</button>
            <p>
              <small class="text-muted">* Required Fields</small>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
