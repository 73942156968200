<div class="content-heading"> Add Coupon
 <small><a [routerLink]=" ['/home'] ">Dashboard</a> ->
          <a [routerLink]="['/coupons/all']"> Coupon </a>->
            New Coupon
 </small>
</div>
<div class="row">
   <div class="col-sm-2"></div>
   <div class="col-sm-12">
   <!-- START panel-->
      <div class="panel panel-default">
         <div class="panel-heading"><strong>New</strong> Coupon</div>
         <div class="panel-body">
            <form (ngSubmit)="onSubmitPromo(f)" #f="ngForm">
               <div class="form-group">
                  <label>Coupon Code*</label>
                  <input class="form-control" type="text" placeholder="Enter Coupon Code" name="promocode" id="promocode" [(ngModel)]="coupon.promocode"/>
               </div>
               <div class="form-group">
                  <label>Coupon Value % *</label>
                  <input class="form-control" type="number" placeholder="Enter Discount % " name="dsctpct" id="dsctpct" [(ngModel)]="coupon.dsctpct"/>
               </div>
               <div class="form-group">
                  <label>Coupon Value (INR) *</label>
                  <input class="form-control" type="number" placeholder="Enter Flat Discount Amt " name="dsctamt" id="dsctamt" [(ngModel)]="coupon.dsctamt"/>
               </div>
               <div class="form-group">
                  <label>Total Max Amount Limit (budget)</label>
                  <input class="form-control" type="number" placeholder="Total Maximum Amount Limit" name="amountlimit" id="amountlimit" [(ngModel)]="coupon.amountlimit"/>
               </div>               
               <div class="form-group">
                  <label>Max Amount Per Transaction</label>
                  <input class="form-control" type="number" placeholder="Maximum Amt Per Transaction Limit" name="amountlimitpertx" id="amountlimitpertx" [(ngModel)]="coupon.amountlimitpertx"/>
               </div>               
               <div class="form-group">
                  <label>Max number of uses</label>
                  <input class="form-control" type="number" placeholder="Enter allowed max uses" name="maxusecount" id="maxusecount" [(ngModel)]="coupon.maxusecount"/>
               </div>
               <div class="form-group">
                  <label>Sellers Applicable to</label>
                  <ng-select
                                        [items]="allsellers"
                                        name="allsellers"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                        [searchable]="true"
                                        bindLabel="name"
                                        bindvalue="sellerid"
                                        placeholder="Select Sellers"
                                        [(ngModel)]="selectedSellers">
                  </ng-select>
               </div>
               <div class="form-group">
                  <label>Rules to apply</label>
                  <ng-select
                                        [items]="allpromorules"
                                        name="promorules"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                        [searchable]="true"
                                        bindLabel="text"
                                        bindvalue="text"
                                        placeholder="Select Promo Rules"
                                        [(ngModel)]="selectedPromoRules">
                  </ng-select>
               </div>
               <div class="form-group">
                  <label>Discount logic to apply</label>
                  <ng-select
                                        [items]="allpromodiscountlogics"
                                        name="promodiscountlogics"
                                        [multiple]="false"
                                        [closeOnSelect]="false"
                                        [searchable]="true"
                                        bindLabel="text"
                                        bindvalue="text"
                                        placeholder="Select Promo Disc Logic to Apply"
                                        [(ngModel)]="selecteddiscountlogic">
                  </ng-select>
               </div>
               <div class="form-group">
                  <label>Valid Dates</label>
                  <br>
                  <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
                  </ngb-datepicker>

                  <ng-template #t let-date let-focused="focused">
                     <span class="custom-day"
                           [class.focused]="focused"
                           [class.range]="isRange(date)"
                           [class.faded]="isHovered(date) || isInside(date)"
                           (mouseenter)="hoveredDate = date"
                           (mouseleave)="hoveredDate = null">
                       {{ date.day }}
                     </span>
                   </ng-template>
                   <hr>

                  <pre>From: {{ coupon.validdates.fromDate | json }} </pre>
                  <pre>To: {{ coupon.validdates.toDate | json }} </pre>
               </div>
               <div class="form-group">
                  <div class="col-md-10">
                    <div class="col-md-2"></div>
                       <div class="col-md-9">
                        <button class="btn btn-primary" type="submit">Submit</button>
                        </div>
                        <div class="col-md-1">
                        <button class="btn btn-danger pull-right s-n-btn" (click)="cancel()">Cancel</button>
                    </div>
                    </div>
                </div>
            </form>
         </div>
      </div>
      <!-- END panel-->
   </div>
</div>