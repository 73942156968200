import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    valForm: FormGroup;

    constructor(public af: AngularFireAuth, public settings: SettingsService, fb: FormBuilder, public router: Router, public db: AngularFireDatabase, public toastr: ToastrService) {

       this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])],
            'name': [null],
            'mobileNo': [null],
            'password': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,15}$')])]
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (const c in this.valForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.valForm.controls[c].markAsTouched();
            }
        }
        if (this.valForm.valid) {
            this.af.createUserWithEmailAndPassword(value.email, value.password)
                .then(success => {
                    this.db.object('/users/' + success.user.uid).update({
                                email: value.email,
                                name: value.name,
                                mobileNo: value.mobileNo,
                                role: 'chef'
                    }).then((res) => {
                        this.toastr.success('Register Successfully!', 'Success!');
                        this.router.navigate(['login']);
                        console.log('Success');
                    });
                },
                (err) => {
                    console.log(err);
                    this.toastr.error('Create Account Failed!', err);
                });
        }
    }

    ngOnInit() {
    }

}
