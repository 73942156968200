import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-locations',
  templateUrl: './edit-locations.component.html',
  styleUrls: ['./edit-locations.component.scss']
})
export class EditLocationsComponent {
  locationDetails: any= {};
  locationdata: AngularFireObject<any>;
  
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase, public toastr: ToastrService) {
  	 	 	this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
  	 	if (Id != null) {
		        this.locationdata = this.af.object('/locations/' + Id);
		        this.locationdata.valueChanges().subscribe(
              (response) => {
                this.locationDetails = response;
              }
		        );
          }
       });
  }

  onSubmitTag(form: NgForm) {
    this.locationdata.update({
      title: this.locationDetails.title,
    }).then((res) => {
      this.toastr.success('Location Updated Successfully!', 'Success!');
      this.router.navigate(['/locations/all']);
    });
  }

  cancel() {
    this.router.navigate(['/locations/all']);
  }
}
