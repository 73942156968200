import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent {

     coupons: Array<any>;
     couponsData: AngularFireList<any>;
    constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService) {
     this.couponsData = af.list('/coupons');
     this.couponsData.snapshotChanges().subscribe((res: any[]) => {
        const _couponsdata = []; 
        res.forEach((_c: any) => {
            const _coupon = _c.payload.toJSON();
            _coupon.$key = _c.key;
            _coupon.amountlimitpertx = _coupon.amountlimitpertx || 0;
            let _sellerarr = [];
            _coupon.sellers = _coupon.sellers || [];
            Object.keys(_coupon.sellers).forEach((key) => {
              _sellerarr.push(_coupon.sellers[key].name)
            }); 
            _coupon.sellerstr = _sellerarr.join(',');
            _couponsdata.push(_coupon);
        })
        this.coupons = _couponsdata;
     });

    }


  couponDelete(key: any) {
    Swal.fire({
            title: 'Are you sure?',
            text: 'Your will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            preConfirm: function() {
              return new Promise<void>(function(resolve) {
                setTimeout(function() {
                  resolve()
                }, 2000)
              })
            }
      }).then((result: any) => {
          if (result.value) {
              this.couponsData.remove(key).then((res) => {
                  Swal.fire('Deleted!', 'Coupons Deleted Successfully!', 'success');
                });
            } else {
              //Swal.fire('Cancelled', 'Your data is safe :)', 'error');
          }
      });
  }

}
