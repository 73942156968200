import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {ToastrService} from 'ngx-toastr';
import {Configs} from '../../../../config';
import {environment} from '../../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { SellerService } from 'app/core/seller/seller.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from 'app/core/user/user.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent {

  url: any = '';
  alltags$: Observable<string[]>;
  selectedTags: any[] = [];
  maxtags = Configs[environment.envname].menuitems.maxtags || 1;
  maxlocations = Configs[environment.envname].menuitems.maxlocations || 1;
  private _disabledV:string = '0';
  public disabled:boolean = false;

  allsellers$: any[];
  selectedSeller: any;
  isAdmin = false;


  menuItems = {
    title: '',
    description: '',
    thumb: '',
    sellerid: '',
    tags: '',
    ntags: {},
    isActive: false,    
    offer: {
      hasOffer: false,
      offerPercentage: 0
    },
    preptimehrs: 0,
    quantityrange: {min: 0, max: 10},
    price: {
      pname: '',
      value: ''
    },
    additionalprice: [],
    extraOptions: [],
    category: {
      id: '',
      name: ''
    }, 
    locationsserved: '',
    seller: {
      name: '',
      thumb: '',
      storename: ''
    }
  };
  currencysymbol = Configs[environment.envname].Currency.symbol;
  ItemPrice: any = {};
  ItemAdditionalPrice = [];
  defaultcategory: any = {};

  categories: Array<any>;
  categoryData: AngularFireList<any>;
  menuItemsData: AngularFireList<any>;
  imageId: string;
  issubmitting: boolean = false;

  uploadedimage: any = null;

  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const menuimagefile = event.target.files[0];
      this.ng2ImgMax.resizeImage(menuimagefile, Configs[environment.envname].menuitems.imagesize.width, Configs[environment.envname].menuitems.imagesize.height).subscribe((imageResized: File) => {
        this.uploadedimage = imageResized;
        const reader = new FileReader();
        reader.onload = (event2: any) => {
          this.url = event2.target.result;
          this.menuItems.thumb = event2.target.result;
          console.log('Image resized.')
          // this.imageRef = 1;
        };
        reader.readAsDataURL(imageResized);
        
        /*
        const path = `menuitems/${new Date().getTime()}_${imageResized.name}`;
        const customMetadata = { app: 'My AngularFire-powered PWA!' };
        // The main task
        this.storage.upload(path, imageResized, { customMetadata })
            .

        // Progress monitoring
        let percentage = task.percentageChanges();
        let snapshot   = task.snapshotChanges()

        // The file's download URL
        let downloadURL = task.downloadURL();
      */ 
      }, (error) => {
        console.log('😢 Oh no. Image Resize Error!', error);
      });

    }
  }


  // addNewChoice = function() {
  //   var newItemNo = this.menuItems.extraOptions.length+1;
  //   this.menuItems.extraOptions.push({});
  // };

  addNewChoice = function () {
    const newItemNo = this.menuItems.extraOptions.length + 1;
    this.menuItems.extraOptions.push({});
  };

  removeChoice = function () {
    if (this.menuItems.extraOptions.length > 0) {
      const lastItem = this.menuItems.extraOptions.length - 1;
      this.menuItems.extraOptions.splice(lastItem);
    }
  };

  addNewaddtlPrice = function () {
    const newItemNo = this.menuItems.additionalprice.length + 1;
    this.menuItems.additionalprice.push({});
  };

  removeaddtlPrice = function () {
    if (this.menuItems.additionalprice.length > 1) {
      const lastItem = this.menuItems.additionalprice.length - 1;
      this.menuItems.additionalprice.splice(lastItem);
    }
  };


  constructor(public af: AngularFireDatabase, public storage: AngularFireStorage, public afa: AngularFireAuth, public toastr: ToastrService, public router: Router, public sellerservice: SellerService, private ng2ImgMax: Ng2ImgMaxService, public userService: UserService) {
    this.menuItemsData = af.list('/menuItems');
    this.categoryData = af.list('/categories');

    this.alltags$ = this.sellerservice.getTags();
    this.isAdmin = this.userService.isAdmin();
    let _sellerinfo = this.sellerservice.getSeller();
    
    this.sellerservice.getAllSellers().then((_sellers: any[]) => {
      this.allsellers$ = _sellers;
      console.log('all sellers: ');
      this.selectedSeller = this.allsellers$.find(x => x.storeName == _sellerinfo.business.storeName);  
    });

    this.categoryData.snapshotChanges().subscribe((categoryresponse: any[]) => {
      const _categories = [];
      categoryresponse.forEach((_o: any) => {
        const _category = _o.payload.toJSON();
        _category.$key = _o.key;
        _categories.push(_category);
      });
      this.categories = _categories;
      if (this.categories.length > 0){
        this.defaultcategory = {
          id: this.categories[0].$key,
          name: this.categories[0].title
        };
      } else {
        this.defaultcategory = {
          id: null,
          name: 'unknown category'
        };
      }
    });
  }

  private get disabledV():string {
    return this._disabledV;
  }

  private set disabledV(value:string) {
    this._disabledV = value;
    this.disabled = this._disabledV === '1';
  }

  public selected(value:any):void {
    console.log('Selected value is: ', value);
  }

  public removed(value:any):void {
    console.log('Removed value is: ', value);
  }

  public typed(value:any):void {
    console.log('New search input: ', value);
  }

  /*public refreshValue(value:any):void {
    this.selectedTags = value.map(eachitem => {
      return eachitem.text;
    });
  }*/


  newselleruser: any = null;
  onSellerChange($event) {
    console.log($event);
    this.userService.getUserById($event.sellerId).then((user) => {
      this.newselleruser = user;
    });
  }

  onSubmitMainItems(form: NgForm) {    
    //this.menuItems.category.name = this.categories.find(x => x.$key == this.menuItems.category.id).title;
    // validate
    if (this.menuItems.title.length > 0) {
      this.issubmitting = true;
      this.menuItems.thumb = ''; // we are not saving base64;
      this.menuItems.category = this.defaultcategory;
      this.menuItems.ntags = {};
      if ((this.selectedTags != null) && (this.selectedTags.length > 0)){
        this.selectedTags.forEach((x, i) => {
          this.menuItems.ntags[x.text] = 1;
        });  
      }
      this.menuItems.tags = '';
      
      console.log(this.menuItems);
      if (this.menuItems.offer.offerPercentage > 0) {
        this.ItemPrice = this.menuItems.price;
        this.ItemPrice.specialPrice = (this.ItemPrice.value - (this.menuItems.offer.offerPercentage * this.ItemPrice.value) / 100);
        
        this.ItemAdditionalPrice = this.menuItems.additionalprice;
        for (let i = 0; i < this.ItemAdditionalPrice.length; i++) {
          this.ItemAdditionalPrice[i].specialPrice = (this.ItemAdditionalPrice[i].value - (this.menuItems.offer.offerPercentage * this.ItemAdditionalPrice[i].value) / 100);
          console.log('this.ItemAdditionalPrice' + JSON.stringify(this.ItemAdditionalPrice));
        }
        this.menuItems.offer.hasOffer = true;
      } else {
        console.log('else');
        this.ItemPrice = this.menuItems.price;
        this.menuItems.offer.offerPercentage = 0;
        this.menuItems.offer.hasOffer = false;
      }

      let _sellerinfo = this.sellerservice.getSeller();

      // Update seller info if changed.      
      if ((this.isAdmin) && (this.menuItems.sellerid !== this.selectedSeller.sellerId)) {
        this.menuItems.sellerid = this.selectedSeller.sellerId;
        this.menuItems.seller = {
          name: this.newselleruser.name,
          storename: this.selectedSeller.storeName,
          thumb: this.selectedSeller.profileimage
        }
      } else {
        let currentuser = this.userService.getCurrentUser();
        this.menuItems.sellerid = currentuser.uid;
        this.menuItems.seller = {
          name: _sellerinfo.name,
          thumb: _sellerinfo.business.profileimage,
          storename: _sellerinfo.business.storeName
        }  
      }

      this.menuItems.locationsserved = _sellerinfo.business.locationsserved;
      this.menuItems.thumb = ''; // we are not saving base64;
      const filePath = Configs[environment.envname].menuitems.menuitemsimagesfolder + '/';
      const storageref = this.storage.ref(filePath);

      if (this.uploadedimage == null) {
        const filename = Configs[environment.envname].menuitems.missingmenuitem;
        this.storage.ref(filePath + filename).getDownloadURL().toPromise().then((downloadurl) => {
          this.menuItems.thumb = downloadurl;
          console.log('menuItems' + JSON.stringify(this.menuItems));
          this.menuItemsData.push(this.menuItems).then((res2) => {
            this.issubmitting = false;
            this.toastr.success('Menu-Items Data Added Successfully!', 'Success!');
            this.router.navigate(['/menu/manageItems']);
          }, (err) => {
            this.toastr.error('Error adding menu item. Error: ' + JSON.stringify(err) , 'Error!');
          });
        }, (err) => {
          console.log('error getting download url for menuitem: ' + err);
        })
      } else {
        const filename = new Date().getTime() + '_' + this.uploadedimage.name;
        this.storage.upload(filePath + filename, this.uploadedimage, {cacheControl: 'public, max-age=31536000'})
          .then((uploaded) => {
            uploaded.ref.getDownloadURL().then((downloadurl) => {
              this.menuItems.thumb = downloadurl;
              console.log('menuItems' + JSON.stringify(this.menuItems));
              this.menuItemsData.push(this.menuItems).then((res2) => {
                this.issubmitting = false;
                this.toastr.success('Menu-Items Data Added Successfully!', 'Success!');
                this.router.navigate(['/menu/manageItems']);
              }, (err) => {
                this.toastr.error('Error adding menu item. Error: ' + JSON.stringify(err) , 'Error!');
              });
            });
          }, (err) => {
            console.log('error getting download url for menuitem: ' + err);
          });
      }      
    } else {
      this.toastr.error('Error adding menu item. Error: Menu Title has to be specified.', 'Error!');
    }
  }

  cancel() {
    this.router.navigate(['/menu/manageItems']);
  }
}
