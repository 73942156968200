<div class="content-heading">
    Dashboard
</div>
<!-- START widgets box-->
<div class="row">
    <div class="col-lg-4 col-sm-6 col-sm-12 showhand" (click)="navigateTo('menu/manageItems')">
        <!-- START widget-->
        <div class="panel widget bg-primary">
            <div class="row row-table">
                <div class="col-xs-4 text-center bg-primary-dark pv-lg">
                    <em class="fa fa-gift fa-3x"></em>
                </div>
                <div class="col-xs-8 pv-lg">
                    <div class="h2 mt0">{{menuItems}}</div>
                    <div class="text-uppercase">Menu Items</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-sm-6 col-sm-12" style="display: none;">
        <!-- START widget-->
        <div class="panel widget bg-purple">
            <div class="row row-table">
                <div class="col-xs-4 text-center bg-purple-dark pv-lg">
                    <em class="fa fa-list-ol  fa-3x"></em>
                </div>
                <div class="col-xs-8 pv-lg">
                    <div class="h2 mt0">
                        {{categories}}
                    </div>
                    <div class="text-uppercase">Categories</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 showhand"  (click)="navigateTo('order/allOrder')">
        <!-- START widget-->
        <div class="panel widget bg-green">
            <div class="row row-table">
                <div class="col-xs-4 text-center bg-green-dark pv-lg">
                    <em class="fa fa-cart-arrow-down fa-3x"></em>
                </div>
                <div class="col-xs-2 pv-lg">
                    <div class="h2 mt0">{{order}}</div>
                    <div class="text-uppercase">Orders</div>
                </div>
                <div class="col-xs-6 pv-lg bg-green-light">
                    <div class="row">
                        <div class="col-xs-2">{{this.orderstats['Pending']}}</div>
                        <div class="col-xs-10">Pending</div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2">{{this.orderstats['On the Way']}}</div>
                        <div class="col-xs-10">On the Way</div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2">{{this.orderstats['Canceled']}}</div>
                        <div class="col-xs-10">Canceled</div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2">{{this.orderstats['Accepted']}}</div>
                        <div class="col-xs-10">Accepted</div>
                    </div>
                </div>
           </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="panel widget bg-yellow">
            <div class="row row-table fixheight bg-yellow">
                <div class="col-xs-4 text-center bg-yellow-dark pv-lg">
                    <span appNow class="text-sm" format="MMMM"></span>
                    <br/>
                    <span appNow class="h2 mt0" format="D"></span>
                </div>
                <div class="col-xs-8 pv-lg">
                    <span appNow class="text-uppercase" format="dddd"></span>
                    <br/>
                    <span appNow class="h2 mt0" format="h:mm:ss"></span>
                    &nbsp;<span appNow class="h2 mt0" format="A"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END widgets box-->
<!-- Chart JS start -->
<div class="row">
    <div class="col-12" *ngIf="barData.datasets[0].data.length == 7">
        <h4>Product Sales Report</h4>
        <div>
            <canvas baseChart [chartType]="'bar'" [options]="barOptions" [datasets]="barData.datasets"
                    [colors]="barColors" [labels]="barData.labels" [legend]="false" height="100"></canvas>
        </div>
    </div>
    <!-- Pie Chart End-->
    <!--
    <div class="col-lg-4" *ngIf="pieData.datasets[0].data.length <= 10 && pieData.datasets[0].data.length >= 5">
        <h4>Category Sales Report</h4>
        <div class="pieHeight">
            <canvas baseChart [chartType]="'pie'" [options]="pieOptions" [datasets]="pieData.datasets"
                    [colors]="pieColors" [labels]="pieData.labels" [legend]="false" height="200"></canvas>
        </div>
    </div>
    -->
</div>