import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent  {

     siteVal: any;
     categories: Array<any>;
     categoryData: AngularFireList<any>;

    constructor(public af: AngularFireDatabase, public router: Router, public toastr: ToastrService) {
        this.categoryData = af.list('/categories');
        this.categoryData.snapshotChanges().subscribe((res: any[]) => {
            const _categoriesitems = [];
            res.forEach((m: any) => {
                const _m = m.payload.toJSON();
                _m.$key = m.key;
                _categoriesitems.push(_m);
            });
            console.log('categories' + JSON.stringify(_categoriesitems));
            this.categories = _categoriesitems;
        });        
    }


  getCategory(ev: any) {
        const val = ev;
        this.categoryData = this.af.list('/categories', ref => ref.orderByChild('title').startAt(val.charAt(0).toUpperCase() + val.slice(1))
                                                                                        .endAt(val.charAt(0).toUpperCase() + val.slice(1) + '\uf8ff'));
        this.categoryData
            .valueChanges().subscribe((data) => {
                this.categories = data;
            });


    }


   categoryShow(key) {
     this.router.navigate(['/categories/viewCategory', key]);
  }

   categoryEdit(key) {
    this.router.navigate(['/categories/editCategory', key]);
  }

categoryDelete(key: any) {
   Swal.fire({
            title: 'Are you sure?',
            text: 'Your will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!'
        }).then((result: any) => {
          if (result.value) {
            this.categoryData.remove(key).then((res) => {
              Swal.fire('Deleted!', 'Categories Data Deleted Successfully!', 'success');
            });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire('Cancelled', 'Your data is safe :)', 'error');
          }
        });

        /*., (isConfirm) => {
            if (isConfirm) {

              } else {

            }
        });*/
  }

}
