import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { environment } from 'environments/environment';
import { Configs } from 'app/config';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.scss']
})
export class EditNewsComponent {
url: any= '';
newsDetails: any= {};
uploadedimage: any = null;
newsdata: AngularFireObject<any>;
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase, private ng2ImgMax: Ng2ImgMaxService, public storage: AngularFireStorage, public toastr: ToastrService) {
  	 	 	this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
  	 	if (Id != null) {
		        this.newsdata = this.af.object('/news/' + Id);
		        this.newsdata.valueChanges().subscribe(
		        (response) => {
		        	this.newsDetails = response;
		         }
		      );
          }
       });
  }

  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const newsimagefile = event.target.files[0];
      this.ng2ImgMax.resizeImage(newsimagefile, Configs[environment.envname].menuitems.imagesize.width, Configs[environment.envname].menuitems.imagesize.height).subscribe((imageResized: File) => {
        this.uploadedimage = imageResized;
        const reader = new FileReader();
        reader.onload = (event2: any) => {
          this.url = event2.target.result;
          this.newsDetails.thumb = event2.target.result;
          console.log('News Image resized.')
        };
        reader.readAsDataURL(imageResized);
      }, (error) => {
        console.log('😢 Oh no. Image Resize Error!', error);
      });
    }
  }

    onSubmitNews(form: NgForm) {
      let date = new Date(); 
      let publishtime = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());    
      
      this.newsDetails.thumb = ''; // we are not saving base64;
      const filePath = Configs[environment.envname].newsitems.newsitemsimagesfolder + '/';
      const storageref = this.storage.ref(filePath);

      if (this.uploadedimage == null) {
        const filename = Configs[environment.envname].newsitems.missingnewsitem;
        this.storage.ref(filePath + filename).getDownloadURL().toPromise().then((downloadurl) => {
          this.newsDetails.thumb = downloadurl;
          this.newsDetails.publishtime = publishtime;
          
          console.log('newsItems' + JSON.stringify(this.newsDetails));
            this.newsdata.update(this.newsDetails).then((res2) => {
              this.toastr.success('News-Items Data Added Successfully!', 'Success!');
              this.router.navigate(['/news/manageNews']);
            }, (err) => {
              this.toastr.error('Error adding news item. Error: ' + JSON.stringify(err) , 'Error!');
            });
          }, (err) => {
            console.log('error getting download url for newsitem: ' + err);
          })
      } else {
        const filename = new Date().getTime() + '_' + this.uploadedimage.name;
        this.storage.upload(filePath + filename, this.uploadedimage, {cacheControl: 'public, max-age=31536000'})
          .then((uploaded) => {
            uploaded.ref.getDownloadURL().then((downloadurl) => {
              this.newsDetails.thumb = downloadurl;
              this.newsDetails.publishtime = publishtime;
              console.log('newsItems' + JSON.stringify(this.newsDetails));
              this.newsdata.update(this.newsDetails).then((res2) => {
                this.toastr.success('News-Items Data Added Successfully!', 'Success!');
                this.router.navigate(['/news/manageNews']);
              }, (err) => {
                this.toastr.error('Error adding news item. Error: ' + JSON.stringify(err) , 'Error!');
              });
            });
          }, (err) => {
            console.log('error getting download url for newsitem: ' + err);
          });
      }
    }
    cancel() {
       this.router.navigate(['/news/manageNews']);
    }
}
