import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.scss']
})
export class ViewUserComponent {




userDetails: any= {};
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase) {
    this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
  	 	    if (Id != null) {
		        this.af.object('/users/' + Id).valueChanges().subscribe(
		          (response) => {
		        	  this.userDetails = response;
		          }
		        );
          }
        });
  }
}
