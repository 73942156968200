import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';

@Component({
  selector: 'app-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.scss']
})
export class ViewItemComponent {

menuDetails: any= null;
menuItemsdata: AngularFireObject<any>;
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase) {
    this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
      if (Id != null) {
        this.menuItemsdata = this.af.object('/menuItems/' + Id);
        this.menuItemsdata.valueChanges().subscribe((response) => {
          this.menuDetails = response;
          let _tags = [];
          for (const [key, value] of Object.entries(this.menuDetails.ntags)) {
            _tags.push(key);
          };
          this.menuDetails.strtags = _tags.join(',');
          this.af.object('/categories/' + response.category.id).valueChanges().subscribe((res: any) => {
            this.menuDetails.categoryTitle = res.title;
          });
          console.log(this.menuDetails);
        });
      }
    });
  }

}
