<div class="content-heading">Orders
<small><a [routerLink]=" ['/home'] ">Dashboard</a> -> Oders</small>
</div>
<div class="panel panel-default">
<div class="panel-heading">Orders Details</div>
  <div class="table-responsive b0">
    <table class="table table-hover">
        <thead>
            <tr>
            <th class="text-center">
                    <strong>Order Id</strong>
            </th>
            <th class="text-center">
                <strong>Order Time</strong>
            </th>
            <th class="text-center">
                    <strong>Name</strong>
                </th>
                <th class="text-center">
                    <strong>Mobile Number</strong>
                </th>
                <th class="text-center">
                    <strong>status</strong>
                </th>
                <th class="text-center">
                    <strong>Total</strong>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center" *ngFor="let order of orders">
                <td (click)="ordersShow(order.$key)" style="cursor: pointer;color:blue;text-decoration:underline;">{{order.suborderId}}</td>
                <td>{{order.createdAt | date:'medium' }}</td>
                <td>{{order.buyerDetails.name}}</td>
                <td>{{order.buyerDetails.mobileNo}}</td>
                <td class="text-center">
                    <span class="label label-success" *ngIf="order.status=='Delivered'">{{order.status}}</span>
                    <span class="label label-success" *ngIf="order.status=='Accepted'">{{order.status}}</span>
                    <span class="label label-warning" *ngIf="order.status=='Pending'">{{order.status}}</span>
                    <span class="label label-purple" *ngIf="order.status=='On the Way'">{{order.status}}</span>
                    <span class="label label-danger" *ngIf="order.status=='Canceled'">{{order.status}}</span>
                </td>
                <td>{{order.grandTotal}}</td>
                <!--
                    <td class="text-center">
                    <select class="input-sm form-control" [(ngModel)]="order.status" (change)="OnChangeStatus(order.$key,$event)">
                        <option>Select Status</option>
                        <option value="Pending">1. Pending</option>
                        <option value="Accepted">2. Accepted</option>
                        <option value="On the Way">3. On the Way</option>
                        <option value="Canceled">4. Canceled</option>
                        <option value="Delivered">5. Delivered</option>
                    </select>
                </td>
                -->
            </tr>
        </tbody>
    </table>
</div>

<div class="panel-footer">
        <div class="row">
        
        </div>
    </div>
</div>