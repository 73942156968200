import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Configs } from 'app/config';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';




@Injectable()
export class CdnServerService {
    public cdnservercode = '';
    public apiroot = '/';

    constructor(private http: HttpClient) {
        let env = environment.envname;
        let versionno = Configs[env].version;
        let versiontext = '(v' + versionno + (env === 'prod' ? ')': '-' + env + ')');
    }

    getcdnserver(): Observable<string> {
        let apiURL = `${this.apiroot}assets/i18n/en.json`;
        return this.http.get(apiURL,  {observe: 'response'})
                    .pipe(
                        map( res => {
                            let headervalue = res.headers.get('x-served-by') || 'NoCDN';
                            return headervalue.split('-').pop();
                        })
                    );
    }
}
