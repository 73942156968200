import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {PushNotificationService} from './push-notification.service';
import {Router, ActivatedRoute} from '@angular/router';
import { Configs } from '../../../config';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss'],
  providers: [PushNotificationService]
})
export class PushNotificationComponent {
  notification: any= {};

  message: any = {
          app_id: Configs[environment.envname].onesignal.app_id,
          contents: {'en': ''},
          headings: {'en': ''},
          included_segments: ['All']
      };

    constructor(public router: Router, public pushNotification: PushNotificationService, public toster: ToastrService) { }


  onpushNotification(form: NgForm) {
  // console.log("notification"+JSON.stringify(this.message));
  this.pushNotification.sendNotification(this.message).subscribe(res => {
    this.toster.success('Notification Send Successfully!', 'Success!');
    });

  }

  cancel() {
        this.router.navigate(['/coupons/all']);

  }
}
