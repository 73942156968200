import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';

import {CategoriesComponent} from './categories/categories.component';
import {AddCategoriesComponent} from './categories/add-categories/add-categories.component';
import {MenuItemsComponent} from './menu-items/menu-items.component';
import {AddItemComponent} from './menu-items/add-item/add-item.component';
import {OrdersComponent} from './orders/orders.component';
import {ViewOrderComponent} from './orders/view-order/view-order.component';
import {UsersComponent} from './users/users.component';
import {AddUserComponent} from './users/add-user/add-user.component';
import {ViewUserComponent} from './users/view-user/view-users.component';
import {SettingsComponent} from './settings/settings.component';
import {TagsComponent} from './tags/tags.component';
import {EditTagesComponent} from './tags/edit-tages/edit-tages.component';
import {AddTagsComponent} from './tags/add-tags/add-tags.component';
import {BusinessInfoComponent} from './business-info/business-info.component';
import {ProfileComponent} from './profile/profile.component';
import {EditCategoryComponent} from './categories/edit-category/edit-category.component';
import {ViewCategoryComponent} from './categories/view-category/view-category.component';
import {ViewItemComponent} from './menu-items/view-item/view-item.component';
import {EditItemComponent} from './menu-items/edit-item/edit-item.component';
import {NewsComponent} from './news/news.component';
import {AddNewsComponent} from './news/add-news/add-news.component';
import {EditNewsComponent} from './news/edit-news/edit-news.component';
import {ViewNewsComponent} from './news/view-news/view-news.component';
import {CouponsComponent} from './coupons/coupons.component';
import {AddCouponsComponent} from './coupons/add-coupons/add-coupons.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {AuthService} from '../pages/login/auth.service';
import {LoginService} from '../pages/login/login.service';


import {NgxPaginationModule} from 'ngx-pagination';
import {DataTableModule} from '@pascalhonegger/ng-datatable';
import { ApplicationPipesModule } from 'app/pipes/applicationpipes.module';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { Configs } from '../../config';
import { environment } from '../../../environments/environment';
import { AddLocationsComponent } from './locations/add-locations/add-locations.component';
import { EditLocationsComponent } from './locations/edit-locations/edit-locations.component';
import { LocationsComponent } from './locations/locations.component';
import { DataFilterPipe } from 'app/pipes/data-filter.pipe';
import { UpdateBusinessBankDetailsComponent } from './business-info/update-bank-details/update-bank-details.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ChefsComponent } from './chefs/chefs.component';
import { ViewChefComponent } from './chefs/view-chef/view-chef.component';
import { AddChefComponent } from './chefs/add-chef/add-chef.component';



// const routes: Routes = [
//             {
//                 path: 'categories',
//                 children: [
//                     {path: 'manageCategories', component: CategoriesComponent, canActivate: [AuthService]},
//                     {path: 'addCategory', component: AddCategoriesComponent, canActivate: [AuthService]},
//                     {path: 'editCategory/:id', component: EditCategoryComponent, canActivate: [AuthService]},
//                     {path: 'viewCategory/:id', component: ViewCategoryComponent, canActivate: [AuthService]}
//                 ]
//             },

//             {
//                 path: 'news',
//                 children: [
//                     {path: 'manageNews', component: NewsComponent, canActivate: [AuthService]},
//                     {path: 'addNews', component: AddNewsComponent, canActivate: [AuthService]},
//                     {path: 'editNews/:id', component: EditNewsComponent, canActivate: [AuthService]},
//                     {path: 'viewNews/:id', component: ViewNewsComponent, canActivate: [AuthService]}
//                 ]
//             },

//             {
//                 path: 'order',
//                 children: [
//                     {path: 'allOrder', component: OrdersComponent, canActivate: [AuthService]},
//                     {path: 'viewOrder/:id', component: ViewOrderComponent, canActivate: [AuthService]},

//                 ]
//             },

//             {
//                 path: 'menu',
//                 children: [
//                     {path: 'manageItems', component: MenuItemsComponent, canActivate: [AuthService]},
//                     {path: 'addItems', component: AddItemComponent, canActivate: [AuthService]},
//                     {path: 'viewItems/:id', component: ViewItemComponent, canActivate: [AuthService]},
//                     {path: 'editItems/:id', component: EditItemComponent, canActivate: [AuthService]},

//                 ]
//             },

//             {
//                 path: 'users',
//                 children: [
//                     {path: 'manageUsers', component: UsersComponent, canActivate: [AuthService]},
//                     {path: 'addUser', component: AddUserComponent, canActivate: [AuthService]},
//                     {path: 'viewUser/:id', component: ViewUserComponent, canActivate: [AuthService]}

//                 ]
//             },

//             {
//                 path: 'tags',
//                 children: [
//                     {path: 'all', component: TagsComponent, canActivate: [AuthService]},
//                     {path: 'addTags', component: AddTagsComponent, canActivate: [AuthService]},
//                     {path: 'editTags/:id', component: EditTagesComponent, canActivate: [AuthService]}
//                 ]

//             },

//             {path: 'setting', component: SettingsComponent, canActivate: [AuthService]},

//             {path: 'businessInfo', component: BusinessInfoComponent, canActivate: [AuthService]},

//             {path: 'UserProfile', component: ProfileComponent, canActivate: [AuthService]},


// ];

@NgModule({
    imports: [
        SharedModule,
       // RouterModule.forChild(routes),
        // ColorPickerModule,
        NgxPaginationModule,
        DataTableModule,
        Ng2ImgMaxModule,
        AngularFireStorageModule,
        ApplicationPipesModule,
        NgSelectModule,         
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        ModalModule.forRoot(),
        NgbTimepickerModule,
        NgbDatepickerModule,
        FormsModule
    ],

    declarations: [
        CategoriesComponent,
        AddCategoriesComponent,
        MenuItemsComponent,
        AddItemComponent,
        OrdersComponent,
        ChefsComponent,
            ViewChefComponent,
            AddChefComponent,
        UsersComponent,
            ViewUserComponent,
            AddUserComponent,
        SettingsComponent,
        TagsComponent,
        EditTagesComponent,
        AddTagsComponent,
        BusinessInfoComponent,
            UpdateBusinessBankDetailsComponent,
        ViewOrderComponent,
        ProfileComponent,
        EditCategoryComponent,
        ViewCategoryComponent,
        NewsComponent,
        AddNewsComponent,
        EditNewsComponent,
        ViewNewsComponent,
        AddLocationsComponent,
        EditLocationsComponent,
        LocationsComponent,
        ViewItemComponent,
        EditItemComponent,
        AddCouponsComponent,
        CouponsComponent,
        PushNotificationComponent
    ],
    providers: [
        // ColorPickerService,
        AuthService,
        LoginService,
    ],

    exports: [RouterModule,
        NgxPaginationModule,
        DataTableModule
    ]
})



export class RestaurantModule {
}
