import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent {

categoryDetails: any= {};
categoriesdata: AngularFireObject<any>;
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase) {
    this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
      console.log('id' + JSON.stringify(Id));
      if (Id != null) {
        this.categoriesdata = this.af.object('/categories/' + Id);
        this.categoriesdata.valueChanges().subscribe((response) => {
          this.categoryDetails = response;
          console.log('response' + JSON.stringify(response));
        });
      }
    });
  }
}
