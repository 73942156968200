import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";

@Injectable()
export class PwaUpdateService {
    constructor(private swUpdate: SwUpdate, public toastr: ToastrService) {
        console.log('PWA Update service....');
        if (!this.swUpdate.isEnabled) {
            console.log('Service worker is not enabled....🙁');
        } else {
            console.log('Service worker is enabled....');
        }
        this.swUpdate.available.subscribe(evt => {
            console.log('New version detected.....');
            const options = {
                "closeButton": false,
                "debug": false,
                "newestOnTop": false,
                "progressBar": false,
                "positionClass": "toast-bottom-center",
                "preventDuplicates": false,
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "5000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };
            this.toastr.info('Click to load the new version', 'New Version detected', {
                "closeButton": false,
                "progressBar": false,
                "positionClass": "toast-bottom-center"
            })
            .onTap
            .pipe(take(1))
            .subscribe(() => window.location.reload() );
        });
    }
}